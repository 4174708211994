import * as React from 'react';

import { IfacilityContact, Itile } from '../../models';
import ReactTable, { FinalState, RowInfo } from 'react-table';
import {
    deleteFacilityFromContact,
    updateSelectedContact
} from '../../actions/manageFacilityActions';

import { ContactActionButton } from './ContactActionButton';
import { TFunction } from 'i18next';
import { TableUtil } from '../common/TableUtil';
import { constants } from '../../constants/constants';

interface Iprops {
    t: TFunction;
    tableData: Array<IfacilityContact>;
    loading: boolean;
    currentTile: Itile;
    facilityID: string;
    selectedContactID: string;
    colorButton: string;
    deleteFacilityFromContact: typeof deleteFacilityFromContact;
    updateSelectedContact: typeof updateSelectedContact;
    addContactLead?: (contact: IfacilityContact) => void;
}
interface Istate {
    selectedRow: any;
    columns: Array<{}>;
}
interface RowInfoEdited extends RowInfo {
    original: IfacilityContact;
}

export class AddContactsTable extends React.PureComponent<Iprops, Istate> {
    static defaultProps = {
        currentTile: constants.getTileByURL(window.location.pathname),
        tableData: []
    };
    constructor(props: Iprops) {
        super(props);
        this.state = {
            selectedRow: {},
            columns: this.columns()
        };
    }
    componentDidUpdate(prevProps: Iprops) {
        if (
            this.props.selectedContactID !== prevProps.selectedContactID &&
            this.props.selectedContactID.length === 0
        ) {
            this.setState({ selectedRow: {} });
        }
    }

    handleSelectContactEdit = (rowInfo: RowInfoEdited) => {
        this.setState({
            selectedRow: rowInfo.index
        });
        this.props.updateSelectedContact(rowInfo.original);
    };

    columns = () =>
        TableUtil.translateHeaders(
            [
                {
                    Header: 'First',
                    accessor: 'firstName',
                    width: 120
                },
                {
                    Header: 'Last',
                    accessor: 'lastName',
                    width: 120
                },
                {
                    Header: 'actions',
                    id: 'actions',
                    minWidth: 20,
                    style: { overflow: 'visible' },
                    Cell: row => {
                        return (
                            <ContactActionButton
                                t={this.props.t}
                                rowInfo={row}
                                colorButton={this.props.colorButton}
                                facilityID={this.props.facilityID}
                                deleteFacilityFromContact={
                                    this.props.deleteFacilityFromContact
                                }
                                handleSelectContactEdit={
                                    this.handleSelectContactEdit
                                }
                                addContactLead={this.props.addContactLead}
                            />
                        );
                    }
                }
            ],
            this.props.t
        );
    /*
     * (reusable)
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    getTrProps = (state: FinalState, rowInfo: RowInfoEdited | undefined) => {
        if (rowInfo) {
            return {
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[
                                  `${this.props.currentTile.color}Tr` as keyof typeof constants.colors
                              ]
                            : ''
                }
            };
        } else {
            return {};
        }
    };

    render() {
        const { tableData, t } = this.props;

        const tableClassName = `beacon-table contacts -highlight ${this.props.currentTile.color}`;
        return (
            <div style={{ marginTop: '20px' }}>
                <ReactTable
                    data={tableData}
                    columns={this.state.columns}
                    getTrProps={this.getTrProps}
                    pageSize={tableData.length >= 2 ? tableData.length : 2}
                    // getTdProps={this.getTdProps}
                    showPageSizeOptions={false}
                    showPagination={false}
                    className={tableClassName}
                    sortable={false}
                    multiSort={false}
                    noDataText={t('facilityContactForm.noContacts')}
                    resizable={false}
                    style={{ height: '100%' }}
                    // loading={loading}
                />
            </div>
        );
    }
}

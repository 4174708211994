// import { constants } from './constants/constants';

// export enum installBaseStatusColorEnum {
//   "Not Tested" = constants.colors.darkGrey,
//   "Pass" = constants.colors.green,
//   "Fail" = constants.colors.red,
//   "Cannot Complete" = constants.colors.darkRed,
//   "Repair" = constants.colors.darkGreyText
// }
export enum DocumentGroups {
    financeAndPlanning,
    leaglAndQSHE,
    reports,
    techSupport
}

export enum leadActivityTypesEnum {
    email,
    phone,
    other
}

export enum measurementPointResultStatusTypesEnum {
    resultStatusNotTested = 0,
    // resultStatusIncomplete = 1, // We removed this 11/2019
    resultStatusFail = 2,
    resultStatusPass = 3,
    resultStatusCannotComplete = 4,
    resultStatusRepaired = 5,
    resultStatusMaintain = 6,
    resultStatusCommissioned = 7
}
/*
 * menuParentKeysEnum helps determin which parent menu item a child item should display
 */
export enum menuParentKeysEnum {
    default,
    admin,
    facility,
    documents,
    sales,
    dashboard
}

export enum workOrderPartsTypeEnum {
    hours = 1,
    part = 0
}

export enum measurementPointTypes {
    QUESTION_PASSFAIL = 1,
    QUESTION_TEXT,
    QUESTION_NUMERIC,
    QUESTION_SELECT,
    GROUP,
    DATE
}

export enum jobDefaultTypeEnum {
    staticPressureValue,
    dynamicFlowRate
}

// This is not the actual statuses, but more of a filter, the API will resolve this to the correct statuses when filtering
export enum jobStatusEnumForFilter {
    All = 0,
    Active = 1,
    Completed = 2
}

export enum measurementSystemEnum {
    None = 0,
    Celcius,
    Bar,
    HG,
    KPA,
    Minutes,
    PPM,
    PSI,
    Fahrenheit,
    Hours,
    Days
}

export enum facilitySiteSizeEnum {
    lessThanFive = 1,
    sixToTwenty,
    twentyOneToFifty,
    fiftyOneToOneHundred,
    oneHundredOneToThreeHundred,
    threeHundredOneToSevenHundred,
    greaterThanSevenHundred
}

export enum workOrderTypesEnum {
    repair = 1,
    pmp,
    warranty,
    servicePlan,
    commissioning
}

export enum workOrderStatusEnum {
    new = 1,
    complete,
    reopened,
    open,
    notApplicable,
    planning
}

// This is not the actual statuses, but more of a filter, the API will resolve this to the correct statuses when filtering
export enum workOrderStatusEnumForFilter {
    All = 0,
    Completed = 1,
    New = 2
}

export enum workOrderResultStatusEnum {
    none,
    repair,
    maintenance
}

export enum workOrderDateRangeUnumForFilter {
    lessThanThreeMonths = 1,
    lessThanSixMonths,
    greaterThanSixMonths
}

export enum workOrderPrioritiesEnum {
    highRisk = 1,
    lowRisk,
    infectionControl
}

export enum workOrderVendorsEnum {
    internal = 1,
    other,
    beacon
}

export enum workOrderTabEnum {
    beacon = 'beacon',
    repair = 'repair',
    preventative = 'preventative'
}

export enum dateRangeEnum {
    lastYear = 'lastYear',
    lastQuarter = 'lastQuarter',
    thisYear = 'thisYear',
    thisQuarter = 'thisQuarter'
}

export enum dataFormatTemperatureEnum {
    celcius,
    fahrenheit
}

export enum dataFormatEnum {
    none = 0,
    date,
    numberTruncate,
    countryTemperature,
    celcius,
    fahrenheit,
    secondsToHoursTruncated,
    pascalToPSITruncated
}

export enum dataUnitEnum {
    none = 0,
    Celsius,
    Bar,
    Hg,
    KPA,
    Minutes,
    PPM,
    PSI,
    Fahrenheit,
    Hours,
    Days
}

export enum leadTypeEnum {
    InspectionDeficiency,
    OldEquipment,
    FSERequestSupport,
    UnreliableEquipment,
    NewEquipment,
    IncompliantEquipment,
    ContactForSupport,
    Connectivity,
    Maintenance,
    EquipmentIsNotSupported,
    NewWorkOrder,
    OldContract,
    Shutdown
}

/*
  New - No results have been saved for the job
  Started - Results are in the job
  Completed - Leader has clicked complete button
  Reopened - Leader reopened the job after closing
  */
export enum jobStatusEnum {
    new = 'New',
    started = 'Started',
    completed = 'Completed',
    reopened = 'Reopened'
}

export enum jobTypesIdEnum {
    Audit = '80eedbac-ec22-45ef-9ac7-f2eb4be2db4c', //
    Commissioning = '1ff033de-c0b6-4f4d-bdb7-7893053092d8',
    Inspection = '524235fd-4633-4b7a-9c13-c37fc39efe69',
    Repair = 'ae78eaa3-89c2-490a-90c6-44e5cfa10b01', // work order based
    Verification = '9c7fde18-0b94-4af8-b4aa-913c40e5aed0',
    Maintenance = '86198f73-1a58-4989-a205-13a42a157989', // work order based
    agsRebalancing = 'bbfd50fe-a790-4384-8a29-00995e5d7158',
    warrantyBM = '6a70ced7-37ce-48c1-92ae-7db47981f896',
    nonProductive = '2AC3BFC5-18FE-4EF1-BFCA-ADF46125AB10',
    servicePlan = '909770e7-54f9-4d7e-8854-0cb5bdc800ad'
}

export enum jobTypesIdEnumForSelect {
    Audit = '80eedbac-ec22-45ef-9ac7-f2eb4be2db4c', //
    Commissioning = '1ff033de-c0b6-4f4d-bdb7-7893053092d8',
    Inspection = '524235fd-4633-4b7a-9c13-c37fc39efe69',
    Repair = 'ae78eaa3-89c2-490a-90c6-44e5cfa10b01', // work order based
    Verification = '9c7fde18-0b94-4af8-b4aa-913c40e5aed0',
    Maintenance = '86198f73-1a58-4989-a205-13a42a157989', // work order based
    agsRebalancing = 'bbfd50fe-a790-4384-8a29-00995e5d7158',
    warrantyBM = '6a70ced7-37ce-48c1-92ae-7db47981f896',
    nonProductive = '2ac3bfc5-18fe-4ef1-bfca-adf46125ab10',
    servicePlan = '909770e7-54f9-4d7e-8854-0cb5bdc800ad'
}

export enum jobTypesIdEnumInverse {
    '80eedbac-ec22-45ef-9ac7-f2eb4be2db4c' = 'Audit',
    '1ff033de-c0b6-4f4d-bdb7-7893053092d8' = 'Commissioning',
    '524235fd-4633-4b7a-9c13-c37fc39efe69' = 'Inspection',
    'ae78eaa3-89c2-490a-90c6-44e5cfa10b01' = 'Repair', // work order based
    '9c7fde18-0b94-4af8-b4aa-913c40e5aed0' = 'Verification',
    '86198f73-1a58-4989-a205-13a42a157989' = 'Maintenance', // work order based
    'bbfd50fe-a790-4384-8a29-00995e5d7158' = 'agsRebalancing',
    '6a70ced7-37ce-48c1-92ae-7db47981f896' = 'warrantyBM',
    '2AC3BFC5-18FE-4EF1-BFCA-ADF46125AB10' = 'nonProductive',
    '909770e7-54f9-4d7e-8854-0cb5bdc800ad' = 'servicePlan'
}

export enum measurementPointListTypeEnum {
    annual = 1,
    verification,
    touchpoint, // maintenance and repair
    agsRebalancing,
    purity, // a single list for a verification job
    verificationChecklist, // a single list for a verification job
    audit,
    Commissioning,
    dailyRounds,
    servicePlan
}

export enum measurementPointListTypeForJobEnum {
    annual = 1,
    verification,
    touchpoint, // maintenance and repair
    agsRebalancing,
    audit = 7,
    Commissioning
}

export enum productLinkTypeEnum {
    specificationSheet = 1,
    salesBrochure,
    operationManual,
    sparePartsBook,
    certificate,
    other
}

export enum reportTypeEnum {
    annualInspection = 1,
    jobNotes,
    HTM,
    visit,
    agsRebalancing,
    verification,
    audit,
    commissioning,
    dailyRounds
}

export enum reportTypeIdEnum {
    'ppmInspection' = '864ae841-fc3d-4ff6-a44f-1d38c6c49fb5',
    'annualInspection' = '19502e5b-3389-436f-8f9a-331d2e7d80d4',
    'verification' = 'a3b37df8-b18d-4507-9efb-7431b505af41',
    'repairWarrantyMaintenance' = '355af604-5f03-42aa-a398-79b566deab1c',
    'jobCommentDeficiency' = 'da1ada41-be53-4214-a66d-7eec693e15cf',
    'agsRebalancing' = '1855041c-f64b-4e46-b147-8ed5a0867ef8',
    'siteSurveyAudit' = '311769cf-c16b-4219-b26e-93c61739616c',
    'dailyRounds' = '0ff13067-d224-4ba7-883b-9cc07788b55a',
    'medicalGasPipelineSystemCommissioning' = '1b18a87b-1d0e-4107-9645-c8745d7914f7',
    'servicePlan' = 'f5e41921-77a7-4b13-a6f9-fbf1b7786afa'
}

export enum reportTypeId {
    '864ae841-fc3d-4ff6-a44f-1d38c6c49fb5' = 'ppmInspection',
    '19502e5b-3389-436f-8f9a-331d2e7d80d4' = 'annualInspection',
    'a3b37df8-b18d-4507-9efb-7431b505af41' = 'verification',
    '355af604-5f03-42aa-a398-79b566deab1c' = 'repairWarrantyMaintenance',
    'da1ada41-be53-4214-a66d-7eec693e15cf' = 'jobCommentDeficiency',
    '1855041c-f64b-4e46-b147-8ed5a0867ef8' = 'agsRebalancing',
    '311769cf-c16b-4219-b26e-93c61739616c' = 'siteSurveyAudit',
    '1b18a87b-1d0e-4107-9645-c8745d7914f7' = 'medicalGasPipelineSystemCommissioning',
    'f5e41921-77a7-4b13-a6f9-fbf1b7786afa' = 'servicePlan'
}

export enum myMedGasContractEnum {
    None = 1,
    MMGService,
    MMGCare,
    MMGCarePlus
}
export enum inspectionContractEnum {
    Won = 1,
    Quoted,
    Lost,
    Renewal,
    Unknown,
    RenewalOffer
}

export enum productCommunicationMethodEnum {
    none,
    TAE
}

export enum leadStatusEnum {
    New,
    Quoted,
    Won,
    Lost,
    InProgress,
    Archived,
    ReadyForQuoting
}

export enum leadStatusEnumSelect {
    New = 0,
    Won = 2,
    Lost,
    InProgress,
    Archived,
    ReadyForQuoting
}

export enum leadRankingEnum {
    Low,
    Medium,
    High
}

export enum beaconContactTypeEnum {
    fse
}

export enum quoteStatusEnum {
    new,
    requestChanges,
    accepted,
    rejected,
    shelved
}

export enum widgetsEnum {
    inspectionStatus = 'inspectionStatus',
    preventativeMaintenance = 'preventativeMaintenance',
    tae = 'tae',
    quotes = 'quotes',
    smartlinks = 'smartlinks'
}

export enum userTypeEnum {
    None,
    Hospital,
    Contractor,
    Plumber,
    Engineer,
    Internal,
    'Surgery Center'
}

export enum userLanguageEnum {
    English,
    French,
    German,
    Spanish,
    Thai,
    Chinese
}

// Profile settings
export enum userTempUnits {
    Celsius = 0,
    Fahrenheit
}

export enum userPressureUnits {
    PSIG = 0,
    BAR
}

export enum UserVacuumUnits {
    mbar = 0,
    mmMg = 1
}

export enum UserPowerUnits {
    HP = 0,
    kW = 1
}

export enum UserFlowUnits {
    lm = 0,
    ls = 1,
    cfm = 2,
    m3h = 3
}

export enum UserCurrency {
    USD = 0,
    GBP = 1,
    EUR = 2
}

// Dashboard
export enum GraphType {
    Guague = 0,
    Numeric,
    Bar,
    Line,
    UnitPrimary,
    UnitSecondary,
    MaintenanceInterval,
    SystemMaintenanceInterval,
    Dryer
}

export enum GraphSize {
    Third = 0, // 33%
    Half, // 50%
    TwoThird, // 66%
    Full // 100%
}

export enum RunStatus {
    NotRunning = 0,
    Running
}

export enum OperatingMode {
    Off = 0,
    Manual,
    Automatic,
    ManualOverrideOff,
    ManualOverrideOn
}

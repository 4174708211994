import * as types from '../actions/actionTypes';

import {
    IWorkOrder,
    Ibuilding,
    Ifacility,
    Ifloor,
    IinstallBase,
    IinstallBasePopulated,
    Ilead,
    Ilocation,
    ImanageInventoryReducer,
    ImanageWorkOrderReducer,
    ImeasurementPointResult,
    Iproduct,
    IproductInfo,
    Iroom,
    IsearchNewProductInstallBatchMode,
    ItableFiltersParams
} from '../models';
import {
    createFormValuesWithName,
    createSelectedIDWithName,
    createTableFiltersWithName,
    modalToggleWithName
} from './commonReducers';
import { filter, keyBy, map, orderBy, pickBy, forEach } from 'lodash';
import initialState, {
    initialInstallBase,
    initialMeasurmentPointResult,
    initialProduct
} from './initialState';
import {
    measurementPointResultStatusTypesEnum,
    workOrderStatusEnum,
    workOrderTypesEnum
} from '../models-enums';

import { TableUtil } from '../components/common/TableUtil';
import { cartReducerWithName } from './cartReducer';
import { combineReducers } from 'redux';
import moment from 'moment';
import { selectWorkOrdersByInstallBaseID } from './manageWorkOrderReducer';
import { IinitialState } from '.';

/*
 * ensure the product at least has the expected attributes, ignore any null attributes
 */
export const cleanAndFilterProductObject = (product: Iproduct): Iproduct => {
    return {
        ...initialProduct,
        ...(pickBy(product, (property, key) => property !== null) as Iproduct),
        installBasesCount: product.installBases
            ? product.installBases.length
            : 0,
        installBases: undefined
    };
};

// clean and filter out deleted and remove the nested objects
const cleanInstallBaseObjects = (
    installBases: IinstallBase[]
): IinstallBase[] => {
    const filteredInstallBases = filter(installBases, { isDeleted: false });
    return filteredInstallBases.map((install: IinstallBase) => ({
        ...initialInstallBase,
        ...(pickBy(install, property => property !== null) as IinstallBase),
        workOrders: undefined,
        latestMeasurementPointListResult: undefined
    }));
};

/*
 * SELECTORS
 */

export const getInstallBasesForProduct = (
    state: { [key: string]: IinstallBase },
    productID: string
) => {
    return filter(state, { productID, isDeleted: false });
};

export const getInstallBasesForFacility = (
    state: { [key: string]: IinstallBase },
    facilityID: string
) => {
    return filter(state, { facilityID, isDeleted: false });
};

const filterInstallBases = (
    installBasesPopulated: IinstallBasePopulated[],
    searchFormValues: ItableFiltersParams
) => {
    const { search, mainCategory, status } = searchFormValues;
    const filteredInstallBases = filter(installBasesPopulated, install => {
        let shouldInclude = true;
        if (search) {
            const searchString = search.trim().toLowerCase();
            const inProductName =
                install.productNameString
                    .toLowerCase()
                    .indexOf(searchString) !== -1;
            const inLocationName =
                install.locationString.toLowerCase().indexOf(searchString) !==
                -1;
            const inSerial = install.serialNumber
                ? install.serialNumber.toLowerCase().indexOf(searchString) !==
                  -1
                : false;
            const inNickname = install.nickname
                ? install.nickname.toLowerCase().indexOf(searchString) !== -1
                : false;
            const inProductDescription = install.product.description
                ? install.product.description
                      .toLowerCase()
                      .indexOf(searchString) !== -1
                : false;
            const inAssetNumber = install.rfid
                ? install.rfid.toLowerCase().indexOf(searchString) !== -1
                : false;

            if (
                inProductName === false &&
                inLocationName === false &&
                inSerial === false &&
                inNickname === false &&
                inProductDescription === false &&
                inAssetNumber === false
            ) {
                shouldInclude = false;
            }
        }
        if (
            mainCategory &&
            install.product.subcategory &&
            mainCategory.value !== install.product.subcategory.mainCategoryID
        ) {
            shouldInclude = false;
        }
        // console.info('main', mainCategory, install.product.subcategory);
        if (
            status &&
            parseInt(status.value, 10) !==
                install.measurementPointListResultStatus
        ) {
            shouldInclude = false;
        }
        if (install.isDeleted) {
            shouldInclude = false;
        }

        return shouldInclude;
    });

    return orderBy(filteredInstallBases, 'locationString');
};

export const getInstallBaseVisibleStatus = (
    installBase: IinstallBase,
    mplResult: ImeasurementPointResult,
    workOrder?: IWorkOrder
) => {
    if (installBase.mostRecentStatus) {
        return installBase.mostRecentStatus;
    }

    // Old code, leaving as a fall back incase mostRecentStatus is not set
    // some point later, we should decide if we want to remove this completely
    if (workOrder && mplResult.id.length) {
        if (
            moment
                .utc(workOrder.updateDate)
                .isAfter(moment.utc(mplResult.createDate)) &&
            workOrder.status === workOrderStatusEnum.complete &&
            workOrder.type === workOrderTypesEnum.repair
        ) {
            return measurementPointResultStatusTypesEnum.resultStatusRepaired;
        }
    }
    // Repair Job
    else if (
        workOrder &&
        !mplResult.id.length &&
        workOrder.status === workOrderStatusEnum.complete &&
        workOrder.type === workOrderTypesEnum.repair
    ) {
        return measurementPointResultStatusTypesEnum.resultStatusRepaired;
    }
    // Service Plan
    else if (
        workOrder &&
        !mplResult.id.length &&
        workOrder.status === workOrderStatusEnum.complete &&
        workOrder.type === workOrderTypesEnum.servicePlan
    ) {
        return measurementPointResultStatusTypesEnum.resultStatusMaintain;
    } else if (
        workOrder &&
        !mplResult.id.length &&
        workOrder.status === workOrderStatusEnum.complete &&
        workOrder.type === workOrderTypesEnum.commissioning
    ) {
        return measurementPointResultStatusTypesEnum.resultStatusCommissioned;
    }

    if (mplResult.id.length) {
        return mplResult.status;
    }

    return installBase.measurementPointListResultStatus;
};

export const selectVisibleInstallBases = (
    manageInventory: ImanageInventoryReducer,
    measurementPointResultsByID: { [key: string]: ImeasurementPointResult },
    facility: Ifacility,
    manageWorkOrderReducer: ImanageWorkOrderReducer,
    productInfo: IproductInfo
) => {
    const { installBasesByID, productsByID, tableFilters } = manageInventory;
    const { subcategories } = productInfo;
    const facilityInstallBases = getInstallBasesForFacility(
        installBasesByID,
        facility.id
    );
    const installBasesPopulated: IinstallBasePopulated[] = map(
        facilityInstallBases,
        install => {
            const product = productsByID[install.productID] || initialProduct;
            const subcategory = subcategories[product.subcategoryID];
            const locationString = TableUtil.buildLocation(install, facility);
            const latestMeasurementPointListResult =
                measurementPointResultsByID[
                    install.latestMeasurementPointListResultID
                ] || initialMeasurmentPointResult;
            const workOrders = selectWorkOrdersByInstallBaseID(
                manageWorkOrderReducer,
                install.id
            );
            const filteredWorkOrders = workOrders.filter(wo => {
                let shouldInclude = true;
                if (wo.type !== workOrderTypesEnum.repair) {
                    shouldInclude = false;
                }
                if (wo.status === workOrderStatusEnum.complete) {
                    shouldInclude = false;
                }
                return shouldInclude;
            });
            const workOrder =
                manageWorkOrderReducer.workOrdersByID[
                    install.latestWorkOrderID
                ];
            const visibleStatus = getInstallBaseVisibleStatus(
                install,
                latestMeasurementPointListResult,
                workOrder
            );

            return {
                ...install,
                product: { ...product, subcategory },
                productNameString: product.name,
                locationString,
                latestMeasurementPointListResult,
                workOrders: filteredWorkOrders,
                visibleStatus
            };
        }
    );

    return filterInstallBases(installBasesPopulated, tableFilters);
};

export const selectVisibleProductsWithInstallBases = (
    manageInventory: ImanageInventoryReducer,
    measurementPointResultsByID: { [key: string]: ImeasurementPointResult },
    facility: Ifacility,
    manageWorkOrderReducer: ImanageWorkOrderReducer,
    productInfo: IproductInfo
) => {
    const selectedInstallBases = selectVisibleInstallBases(
        manageInventory,
        measurementPointResultsByID,
        facility,
        manageWorkOrderReducer,
        productInfo
    );
    let visibleProducts: { [key: string]: Iproduct } = {};
    selectedInstallBases.forEach(install => {
        const foundProduct = manageInventory.productsByID[install.productID];
        const foundInstallBases =
            visibleProducts[install.productID] &&
            visibleProducts[install.productID].installBases
                ? visibleProducts[install.productID].installBases
                : [];
        const installBases = foundInstallBases
            ? [...foundInstallBases, install]
            : [install];
        visibleProducts = {
            ...visibleProducts,
            [install.productID]: {
                ...foundProduct,
                installBases,
                installBasesCount: installBases.length
            }
        };
    });
    return orderBy(visibleProducts, 'name');
};

export const selectMainCategoryFromProductID = (
    state: IinitialState,
    props: { productID: string }
) => {
    const { manageInventory: manageInventoryState, productInfo } = state;
    const { productsByID } = manageInventoryState;
    const product = productsByID[props.productID];
    const subCategoryID = product.subcategoryID;
    const subCategory = productInfo.subcategories[subCategoryID];
    const mainCategoryID = subCategory.mainCategoryID;
    return productInfo.mainCategories[mainCategoryID];
};

/*
 * filter by facility first
 * the rest of the filteres are handled by filterInstallBases()
 * add the entire product object to each install
 * filter the work orders for repair type and not being complete
 */

/*
 * select the VisibleInstalls then group them under the product
 */

/*
 * REDUCERS
 */

/*
status = repair When the work order status is type “repair”, it is completed, and the latestWorkOrder updateDate is before the latestMPLResult
or:
there is a completed repair work order and no latestMPLresult.
or:
status =  latestmMPLResult.status
or:
status = Not Tested
*/

/*
 * the product object is returned with all the installBases nested inside of it
 */
function productsByIDReducer(
    state: { [key: string]: Iproduct } = initialState.manageInventory
        .productsByID,
    action: any
): { [key: string]: Iproduct } {
    switch (action.type) {
        case types.GET_INVENTORY_SUCCESS: {
            const newProducts = keyBy(
                map(action.inventory, ({ product }: { product: Iproduct }) => {
                    return cleanAndFilterProductObject(product);
                }),
                'id'
            );
            return { ...state, ...newProducts };
            //return newProducts;
        }
        case types.PRODUCT_UPDATE_SUCCESS:
            return {
                ...state,
                [action.product.id]: cleanAndFilterProductObject(action.product)
            };

        case types.PRODUCT_ADD_SUCCESS:
            return {
                ...state,
                [action.product.id]: cleanAndFilterProductObject(action.product)
            };
        case types.INSTALL_ADD_SUCCESS: {
            // if this is ther first install for this product, we are adding the product as well
            const newInstallsCount = action.installs.length;
            const product = action.product;
            const newCount = product.installBasesCount
                ? product.installBasesCount + newInstallsCount
                : newInstallsCount;
            return {
                ...state,
                [product.id]: { ...product, installBasesCount: newCount }
            };
        }
        case types.LEADS_MANAGE_SUCCESS: {
            return {
                ...state,
                ...keyBy(
                    action.leads
                        .filter((lead: Ilead) => lead.product)
                        .map((lead: Ilead) => lead.product),
                    'id'
                )
            };
        }
        // we could handle get_products_success here if we were keeping all the products and doing front end filtering of the manage inventory table
        // case types.GET_PRODUCTS_SUCCESS:
        //   if (action.products) {
        //     const newProducts = map(action.products, (prod: Iproduct) => {
        //       return cleanAndFilterProductObject({
        //         ...prod,
        //         installBases: state[prod.id] ? state[prod.id].installBases : []
        //       });
        //     });
        //     return { ...state, ...keyBy(newProducts, 'id') };
        //   } else {
        //     return state;
        //   }

        case types.DELETE_PHOTO_SUCCESS: {
            if (action.productID && state[action.productID]) {
                const productWithoutPhoto: Iproduct = {
                    ...state[action.productID],
                    imagePath: ''
                };
                return {
                    ...state,
                    [productWithoutPhoto.id]: productWithoutPhoto
                };
            }
        }
        case types.UPLOAD_PRODUCT_PHOTO_SUCCESS: {
            if (action.productID && state[action.productID]) {
                const productWithPhoto: Iproduct = {
                    ...state[action.productID],
                    imagePath: action.photo.lightboxUrl
                };
                return { ...state, [productWithPhoto.id]: productWithPhoto };
            }
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageInventory.productsByID;
        default:
            return state;
    }
}

function installBasesByIDReducer(
    state: { [key: string]: IinstallBase } = initialState.manageInventory
        .installBasesByID,
    action: any
): { [key: string]: IinstallBase } {
    switch (action.type) {
        case types.GET_INVENTORY_SUCCESS: {
            let newInstallBases: IinstallBase[] = [];
            action.inventory.forEach(({ product }: any) => {
                newInstallBases = [
                    ...newInstallBases,
                    ...cleanInstallBaseObjects(product.installBases)
                ];
            });

            return { ...state, ...keyBy(newInstallBases, 'id') };
            //return keyBy(newInstallBases, 'id');
        }
        case types.LEADS_MANAGE_SUCCESS: {
            if (action.leads) {
                let nInstallBases: IinstallBase[] = [];
                forEach(action.leads, (lead: Ilead) => {
                    if (lead.leadInstallBases) {
                        forEach(lead.leadInstallBases, leadInstallB => {
                            if (leadInstallB.installBase) {
                                nInstallBases = [
                                    ...nInstallBases,
                                    leadInstallB.installBase
                                ];
                            }
                        });
                    }
                });
                return { ...state, ...keyBy(nInstallBases, 'id') };
            } else {
                return state;
            }
        }
        case types.INSTALL_UPDATE_BULK:
            return { ...state, ...keyBy(action.installs, 'id') };

        case types.INSTALL_UPDATE_SUCCESS:
            return { ...state, [action.payload.id]: action.payload };

        case types.INSTALL_DELETE_SUCCESS:
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    isDeleted: true
                }
            };
        case types.LOCATION_DELETE_SUCCESS: {
            const locationObject = action.locationObject as
                | Ilocation
                | Ibuilding
                | Ifloor
                | Iroom;
            let locationKey:
                | 'buildingID'
                | 'floorID'
                | 'locationID'
                | 'roomID' = 'buildingID';
            if ('facilityID' in locationObject) {
                // BUILDING
                locationKey = 'buildingID';
                return keyBy(
                    map(state, install => {
                        if (install[locationKey] === locationObject.id) {
                            return {
                                ...install,
                                [locationKey]: '',
                                floorID: '',
                                locationID: '',
                                roomID: ''
                            };
                        } else {
                            return install;
                        }
                    }),
                    'id'
                );
            } else if ('buildingID' in locationObject) {
                // FLOOR
                locationKey = 'floorID';
                return keyBy(
                    map(state, install => {
                        if (install[locationKey] === locationObject.id) {
                            return {
                                ...install,
                                [locationKey]: '',
                                locationID: '',
                                roomID: ''
                            };
                        } else {
                            return install;
                        }
                    }),
                    'id'
                );
            } else if ('floorID' in locationObject) {
                // LOCATION
                locationKey = 'locationID';
                return keyBy(
                    map(state, install => {
                        if (install[locationKey] === locationObject.id) {
                            return {
                                ...install,
                                [locationKey]: '',
                                roomID: ''
                            };
                        } else {
                            return install;
                        }
                    }),
                    'id'
                );
            } else if ('locationID' in locationObject) {
                // ROOM
                locationKey = 'roomID';
                return keyBy(
                    map(state, install => {
                        if (install[locationKey] === locationObject.id) {
                            return { ...install, [locationKey]: '' };
                        } else {
                            return install;
                        }
                    }),
                    'id'
                );
            }
        }
        /*
         * It is possible to add multiple installs at the same time.
         */
        // eslint-disable-line no-fallthrough
        case types.INSTALL_ADD_SUCCESS: {
            const newInstallsB = cleanInstallBaseObjects(action.installs);
            return { ...state, ...keyBy(newInstallsB, 'id') };
        }
        case types.ADD_MEASUREMENT_POINT_RESULT: {
            const installB = state[action.result.installBaseID];
            return {
                ...state,
                [installB.id]: {
                    ...installB,
                    measurementPointListResultStatus: action.result.status
                }
            };
        }
        case types.UPDATE_MEASUREMENT_POINT_RESULT: {
            const installC = state[action.result.installBaseID];
            return {
                ...state,
                [installC.id]: {
                    ...installC,
                    measurementPointListResultStatus: action.result.status
                }
            };
        }
        case types.USER_LOGOUT_SUCCESS: {
            return initialState.manageInventory.installBasesByID;
        }
        default:
            return state;
    }
}

function totalPages(state = 1, action: any): number {
    switch (action.type) {
        case types.INVENTORY_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return 1;
        default:
            return state;
    }
}

function productSearchModalBatchMode(
    state: IsearchNewProductInstallBatchMode | undefined,
    action: any
): IsearchNewProductInstallBatchMode {
    switch (action.type) {
        case types.SET_INSTALL_BATCH_MODE:
            return action.payload;
        default:
            return state || { isBatchMode: false, installBaseIDs: [] };
    }
}

function selectedProductReducer(
    state: Iproduct = initialProduct,
    action: any
): Iproduct {
    switch (action.type) {
        case types.SET_SELECTED_PRODUCT:
            return action.product ? action.product : initialProduct;
        case types.USER_LOGOUT_SUCCESS:
            return initialProduct;
        default:
            return state;
    }
}

function newProductsReducer(
    state: { [key: string]: Iproduct } = {},
    action: any
): { [key: string]: Iproduct } {
    switch (action.type) {
        case types.GET_PRODUCTS_SUCCESS:
            if (action.products) {
                const newProducts = map(
                    action.products,
                    (product: Iproduct) => {
                        return cleanAndFilterProductObject(product);
                    }
                );
                return keyBy(newProducts, 'id');
            } else {
                return {};
            }
        case types.NEW_PRODUCTS_RESET:
            return {};
        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

const ManageInventory = combineReducers<ImanageInventoryReducer>({
    productsByID: productsByIDReducer,
    installBasesByID: installBasesByIDReducer,
    totalPages,
    cart: (state, action) => cartReducerWithName(state, action, 'INVENTORY'),
    searchNewProductsSelectedProduct: (state, action) =>
        selectedProductReducer(state, action),
    newProducts: newProductsReducer,
    showShoppingCartModal: (state, action) =>
        modalToggleWithName(state, action, 'SHOPPING_CART_INVENTORY'),
    showEditProductModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_PRODUCT'),
    showSearchNewProductsModal: (state, action) =>
        modalToggleWithName(state, action, 'SEARCH_NEW_PRODUCTS'),
    showEditInstallModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_INSTALL'),
    showInstallContactModal: (state, action) =>
        modalToggleWithName(state, action, 'INSTALL_CONTACT'),
    showImportInstallModal: (state, action) =>
        modalToggleWithName(state, action, 'IMPORT_INSTALL'),
    showMPResultModal: (state, action) =>
        modalToggleWithName(state, action, 'MP_RESULT'),
    showMPResultHistoryModal: (state, action) =>
        modalToggleWithName(state, action, 'MP_RESULT_HISTORY'),
    showMPResultNotesModal: (state, action) =>
        modalToggleWithName(state, action, 'MP_RESULT_NOTES'),
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_INVENTORY'),
    productSearchFormValues: (state, action) =>
        createFormValuesWithName(
            state,
            action,
            'MANAGE_INVENTORY_PRODUCT_SEARCH'
        ),
    installFormValues: (state, action) =>
        createFormValuesWithName(state, action, 'MANAGE_INVENTORY_INSTALL'),
    selectedProductID: (state, action) =>
        createSelectedIDWithName(state, action, 'MANAGE_INVENTORY_PRODUCT_ID'),
    selectedInstallBaseID: (state, action) =>
        createSelectedIDWithName(
            state,
            action,
            'MANAGE_INVENTORY_INSTALL_BASE_ID'
        ),
    searchNewProductInstallBatchMode: (state, action) =>
        productSearchModalBatchMode(state, action)
    // quoteRequestItems: quoteRequestReducer(state, action)
});

export default ManageInventory;

/*
 * UserProfile form
 * Edit your profile
 */

import * as React from 'react';
import { Button, Col, FormGroup } from 'react-bootstrap';
import { WithTranslation } from 'react-i18next';
import {
    AbstractControl,
    FieldConfig,
    FormGenerator,
    Observable
} from 'react-reactive-form';
import { toastr } from 'react-redux-toastr';
import { constants } from '../../constants/constants';

import { debounce, differenceBy, filter, find, forEach } from 'lodash';
import { IfacilityWithoutBuildings, Ioption, Iuser } from '../../models';

import {
    FacilitiesSearchParams,
    searchFacilitiesForAsyncSelect
} from '../../actions/commonActions';
import { getCodes } from '../../actions/manageCodeActions';
import {
    deleteUserAccount,
    updateUserProfile
} from '../../actions/userActions';
import { langOption } from '../../i18n';
import {
    UserCurrency,
    UserFlowUnits,
    UserPowerUnits,
    UserVacuumUnits,
    userLanguageEnum,
    userPressureUnits,
    userTempUnits
} from '../../models-enums';
import { FormUtil } from '../common/FormUtil';
import { userBaseConfigControls } from '../common/UserBaseConfigControls';

interface IstateChanges extends Observable<any> {
    next: () => void;
}

interface AbstractControlEdited extends AbstractControl {
    stateChanges: IstateChanges;
}

interface Iprops {
    updateUserProfile: typeof updateUserProfile;
    toggleModal: () => void;
    loading: boolean;
    colorButton: string;
    user: Iuser;
    facilityOptions: Ioption[];
    standards: Ioption[];
    getFacilitiesByCountry: (params: FacilitiesSearchParams) => void;
    deleteUserAccount: typeof deleteUserAccount;
    searchFacilitiesForAsyncSelect: typeof searchFacilitiesForAsyncSelect;
    getCodes: typeof getCodes;
    getProductInfo: () => void;
}

interface Istate {
    fieldConfig: FieldConfig;
}

class UserProfileForm extends React.Component<
    Iprops & WithTranslation,
    Istate
> {
    private formGroup: FormGroup | any;
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.state = {
            fieldConfig: this.buildFieldConfig(
                this.props.facilityOptions,
                this.props.user
            )
        };
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillMount() {
        // get the most up to date facilities from the API
        this.props.getFacilitiesByCountry({});
        this.props.getCodes();
    }

    componentDidMount() {
        // set values
        this.patchUserValues();

        if (!this.props.standards?.length) this.props.getProductInfo();
    }

    componentDidUpdate(prevProps: Iprops) {
        if (
            differenceBy(
                prevProps.facilityOptions,
                this.props.facilityOptions,
                'value'
            ).length ||
            prevProps.facilityOptions.length !==
                this.props.facilityOptions.length
        ) {
            const facilitySelectControl = this.formGroup.get(
                'facilities'
            ) as AbstractControlEdited;

            facilitySelectControl.meta.options = this.props.facilityOptions;
            facilitySelectControl.stateChanges.next();

            const facilitiesArray = filter(
                this.props.facilityOptions,
                (fac: any) => {
                    return find(this.props.user.facilities, {
                        id: fac.value
                    })
                        ? true
                        : false;
                }
            );

            this.setState({
                fieldConfig: this.buildFieldConfig()
            });
            this.formGroup.patchValue({ facilities: facilitiesArray });
        }

        if (this.props.standards?.length !== prevProps.standards?.length) {
            const userStandards = this.props.standards.filter(option =>
                this.props.user.userStandards.includes(option.value)
            );
            this.setState(
                {
                    fieldConfig: this.buildFieldConfig(
                        this.props.facilityOptions,
                        this.props.user
                    )
                },
                () => {
                    this.patchUserValues();
                    this.formGroup.patchValue({ userStandards });
                }
            );
        }
    }

    patchUserValues = () => {
        forEach(this.props.user, (value, key) => {
            // Not sure why this form was populated with this patchValue...but these Ioptions will be set inside of buildFieldConfig
            if (
                key !== 'language' &&
                key !== 'tempUnits' &&
                key !== 'pressureUnits' &&
                key !== 'vacuumUnits' &&
                key !== 'powerUnits' &&
                key !== 'flowUnits' &&
                key !== 'currency' &&
                key !== 'userStandards'
            ) {
                this.formGroup.patchValue({ [key]: value });
            }
        });

        const { facilities, countryID } = this.props.user;
        const emailControl = this.formGroup.get(
            'email'
        ) as AbstractControlEdited;
        emailControl.disable();

        const facilitiesArray = filter(
            this.props.facilityOptions,
            (fac: any) => {
                return find(facilities, { id: fac.value }) ? true : false;
            }
        );
        this.formGroup.patchValue({ facilities: facilitiesArray });

        if (countryID) {
            const selectedCountryOption = constants.countries[countryID];
            FormUtil.patchControl(
                this.formGroup,
                'countryID',
                selectedCountryOption
            );
        }
    };

    saveFacilities = (facilities: IfacilityWithoutBuildings[]) => {
        // console.log(facilities)
    };

    loadOptions = debounce((searchTerm, callback) => {
        if (searchTerm.length >= 3) {
            this.props.searchFacilitiesForAsyncSelect(
                searchTerm,
                null,
                null,
                callback,
                this.saveFacilities
            );
        } else {
            callback([]);
        }
    }, 100);

    buildFieldConfig = (
        facilityOptions = this.props.facilityOptions,
        user = this.props.user
    ) => {
        const languageOptions = FormUtil.convertEnumToOptions(userLanguageEnum);
        const tempOptions = FormUtil.convertEnumToOptions(userTempUnits);
        const pressureOptions = FormUtil.convertEnumToOptions(
            userPressureUnits
        );
        const vacuumOptions = FormUtil.convertEnumToOptions(UserVacuumUnits);
        const powerOptions = FormUtil.convertEnumToOptions(UserPowerUnits);
        const flowOptions = FormUtil.convertEnumToOptions(UserFlowUnits);
        const currencyOptions = FormUtil.convertEnumToOptions(UserCurrency);

        const languageValue =
            user.language !== undefined ? user.language.toString() : undefined;
        const tempUnits =
            user.tempUnits !== undefined
                ? user.tempUnits.toString()
                : undefined;
        const pressureUnits =
            user.pressureUnits !== undefined
                ? user.pressureUnits.toString()
                : undefined;
        const vacuumUnits =
            user.vacuumUnits !== undefined
                ? user.vacuumUnits.toString()
                : undefined;
        const powerUnits =
            user.powerUnits !== undefined
                ? user.powerUnits.toString()
                : undefined;
        const flowUnits =
            user.flowUnits !== undefined
                ? user.flowUnits.toString()
                : undefined;
        const currency =
            user.currency !== undefined ? user.currency.toString() : undefined;

        const userStandardsValues = this.props.standards.filter(
            (value: Ioption) => {
                return user.userStandards.includes(value.value);
            }
        );

        // Values from formValues comes back as either an Ioption (object) or as a string | number (weird right?)
        // Even better, languageValue, flowUnits, etc are suppose to be strings, but they are actually numbers, so don't compare with ===
        let lang =
            typeof languageValue === 'object'
                ? languageValue
                : languageOptions.find(x => x.value == languageValue);
        let flow =
            typeof flowUnits === 'object'
                ? flowUnits
                : flowOptions.find(x => x.value == flowUnits);
        let temp =
            typeof tempUnits === 'object'
                ? tempUnits
                : tempOptions.find(x => x.value == tempUnits);
        let pressure =
            typeof pressureUnits === 'object'
                ? pressureUnits
                : pressureOptions.find(x => x.value == pressureUnits);
        let vacuum =
            typeof vacuumUnits === 'object'
                ? vacuumUnits
                : vacuumOptions.find(x => x.value == vacuumUnits);
        let power =
            typeof powerUnits === 'object'
                ? powerUnits
                : powerOptions.find(x => x.value == powerUnits);
        let currencyOption =
            typeof currency === 'object'
                ? currency
                : currencyOptions.find(x => x.value == currency);

        // Field config to configure form
        const fieldConfigControls = {
            countryID: {
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                render: FormUtil.Select,
                meta: {
                    options: constants.countries,
                    label: 'user:country',
                    colWidth: 12,
                    placeholder: 'manageUserQueue:countrySearchPlaceholder',
                    name: 'country'
                },
                formState: {
                    value: constants.countries[constants.defaultCountryID],
                    disabled: true
                }
            },
            facilities: {
                render: FormUtil.AsyncSelect,
                meta: {
                    label: 'common:facility',
                    loadOptions: (searchTerm: string, callback: any) => {
                        this.loadOptions(searchTerm, callback);
                    },
                    colWidth: 12,
                    placeholder: 'manageUserQueue:facilitySearchPlaceholder',
                    isMulti: true,
                    name: 'facilities'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: { value: null, disabled: true }
            },
            language: {
                render: FormUtil.Select,
                meta: {
                    options: languageOptions,
                    label: 'common:language',
                    colWidth: 12,
                    placeholder: 'manageUserQueue:facilitySearchPlaceholder',
                    isMulti: false,
                    name: 'language',
                    last: true
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: lang,
                    disabled: false
                }
            },
            tempUnits: {
                render: FormUtil.Select,
                meta: {
                    options: tempOptions,
                    label: 'common:tempatureUnits',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'tempUnits'
                },
                formState: {
                    value: temp,
                    disabled: false
                }
            },
            pressureUnits: {
                render: FormUtil.Select,
                meta: {
                    options: pressureOptions,
                    label: 'common:pressureUnits',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'pressureUnits'
                },
                formState: {
                    value: pressure,
                    disabled: false
                }
            },
            vacuumUnits: {
                render: FormUtil.Select,
                meta: {
                    options: vacuumOptions,
                    label: 'user:vacuumUnits',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'vacuumUnits'
                },
                formState: {
                    value: vacuum,
                    disabled: false
                }
            },
            powerUnits: {
                render: FormUtil.Select,
                meta: {
                    options: powerOptions,
                    label: 'user:powerUnits',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'powerUnits'
                },
                formState: {
                    value: power,
                    disabled: false
                }
            },
            flowUnits: {
                render: FormUtil.Select,
                meta: {
                    options: flowOptions,
                    label: 'user:flowUnits',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'flowUnits'
                },
                formState: {
                    value: flow,
                    disabled: false
                }
            },
            currency: {
                render: FormUtil.Select,
                meta: {
                    options: currencyOptions,
                    label: 'user:currency',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'currency'
                },
                formState: {
                    value: currencyOption,
                    disabled: false
                }
            },
            userStandards: {
                render: FormUtil.Select,
                meta: {
                    options: this.props.standards,
                    label: 'common:standards',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: true,
                    name: 'userStandards'
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: userStandardsValues,
                    disabled: false
                }
            }
        };

        const fieldConfig: FieldConfig = {
            controls: { ...userBaseConfigControls(), ...fieldConfigControls }
        };

        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    /*
     * formValuesToItem - convert the formValues to the shape of the selectedJob
     */
    formValuesToItem = (updatedValue?: { [key: string]: any }): Iuser => {
        let formValues = this.formGroup.value;
        if (updatedValue) {
            formValues = { ...formValues, ...updatedValue };
        }
        formValues = FormUtil.getValues(formValues);
        return {
            ...this.props.user,
            ...formValues,
            widgets: formValues.widgets ? formValues.widgets.join(',') : '' // W/o empty string saves all available widgets to profile
        };
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }

        const lang = langOption(this.formGroup.value.language);

        this.props.i18n
            .changeLanguage(lang)
            .then(() => {
                toastr.success(
                    'Success',
                    `Language changed to ${this.formGroup.value.language.label}.`,
                    constants.toastrSuccess
                );
            })
            .catch(() => {
                toastr.error(
                    'Error',
                    `Failed to update language to ${this.formGroup.value.language.label}.`,
                    constants.toastrError
                );
            });
        this.props.updateUserProfile(this.formValuesToItem());
    };

    handleDelete = () => {
        const toastrConfirmOptions = {
            onOk: () => {
                this.handleDeleteSecondConfirmation();
            },
            onCancel: () => console.info('CANCEL: clicked'),
            okText: this.props.t('delete account'),
            cancelText: this.props.t('common:cancel')
        };
        toastr.confirm(
            this.props.t('userAccountDeleteConfirm'),
            toastrConfirmOptions
        );
    };

    handleDeleteSecondConfirmation = () => {
        const toastrConfirmOptions = {
            onOk: () => {
                this.props.deleteUserAccount();
            },
            onCancel: () => console.info('CANCEL: clicked'),
            okText: this.props.t('common:confirm'),
            cancelText: this.props.t('common:cancel')
        };
        toastr.confirm(
            this.props.t('userAccountDeleteSecondConfirm'),
            toastrConfirmOptions
        );
    };

    setForm = (form: AbstractControl) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
    };

    render() {
        const { t } = this.props;

        const formClassName = `clearfix beacon-form ${this.props.colorButton}`;

        return (
            <form onSubmit={this.handleSubmit} className={formClassName}>
                <FormGenerator
                    onMount={this.setForm}
                    fieldConfig={this.state.fieldConfig}
                />
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={this.props.toggleModal}
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button
                        bsStyle="danger"
                        style={{ marginRight: '15px' }}
                        type="button"
                        className=""
                        disabled={this.props.loading}
                        onClick={this.handleDelete}
                    >
                        {t('user:delete account')}
                    </Button>
                    <Button
                        bsStyle={this.props.colorButton}
                        type="submit"
                        disabled={this.props.loading}
                    >
                        {t('save')}
                    </Button>
                </Col>
            </form>
        );
    }
}

export default UserProfileForm;

import { debounce, omit } from 'lodash';
import * as React from 'react';
import { Button, Col } from 'react-bootstrap';
import { WithTranslation } from 'react-i18next';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup,
    Validators
} from 'react-reactive-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import {
    FacilitiesSearchParams,
    searchFacilitiesForAsyncSelect,
    toggleEditFacilityModal
} from '../../actions/commonActions';
import { loadCountries } from '../../actions/countriesActions';
import {
    getSecurityUsers,
    saveUser,
    toggleSecurityFunctionsModal,
    updateSelectedUser
} from '../../actions/manageUserActions';
import { constants } from '../../constants/constants';
import {
    Icountry,
    Ifacility,
    IfacilityWithoutBuildings,
    Ihistory,
    Ioption,
    IproductInfo,
    Iuser
} from '../../models';
import {
    UserCurrency,
    UserFlowUnits,
    UserPowerUnits,
    UserVacuumUnits,
    userLanguageEnum,
    userPressureUnits,
    userTempUnits,
    userTypeEnum
} from '../../models-enums';
import { IgenericFieldControls, IgenericFormValues } from '../../modelsForms';
import { getCountries } from '../../reducers/countriesReducer';
import { convertFacillitiesToOptionsWithCountryID } from '../../reducers/facilitiesReducer';
import EditFacilityModal from '../common/EditFacilityModal';
import {
    FormUtil,
    MultiValueLabel,
    MultiValueOption
} from '../common/FormUtil';
import { userBaseConfigControls } from '../common/UserBaseConfigControls';
import UserTrainingDataModal from '../common/UserTrainingDataModal';
import EditUserTrainingForm from './EditUserTrainingForm';
import {
    editUserFormValuesToItem,
    editUserItemToFormValues
} from './EditUserUtils';
import { manageUserQueryParamsEnum } from './ManageUserChild';

interface Iprops {
    user: Iuser;
    selectedUser: Iuser;
    history: Ihistory;
    loading: boolean;
    colorButton: string;
    getSecurityUsers: typeof getSecurityUsers;
    salesManagerOptions: Ioption[];
    getFacilitiesByCountry: (params: FacilitiesSearchParams) => Promise<void>;
    toggleEditFacilityModal: typeof toggleEditFacilityModal;
    toggleSecurityFunctionsModal: typeof toggleSecurityFunctionsModal;
    queryParams: typeof manageUserQueryParamsEnum;
    onChange: typeof updateSelectedUser;
    facilitiesByID: { [key: string]: IfacilityWithoutBuildings };
    onSubmit: typeof saveUser;
    securityOptions: Ioption[];
    facilityOptionsWithAddress: Ioption[];
    closeModal: () => void;
    searchFacilitiesForAsyncSelect: typeof searchFacilitiesForAsyncSelect;
    productInfo: IproductInfo;
}

const engineerValidators = [
    Validators.pattern('^[0-9A-Za-z]+$'),
    Validators.minLength(1),
    Validators.maxLength(8)
];

const EditUserForm = (props: Iprops & WithTranslation) => {
    const {
        securityOptions,
        selectedUser,
        salesManagerOptions,
        facilitiesByID,
        colorButton,
        loading,
        t
    } = props;
    const [form, setForm] = React.useState<FormGroup | FormArray>();
    const [showTrainingModal, setShowTrainingModal] = React.useState<boolean>(
        false
    );
    const [showUserTrainingForm, setShowUserTrainingForm] = React.useState<
        boolean
    >(false);
    const allCountries = useSelector(getCountries);
    const dispatch = useDispatch();

    const itemToFormValues = React.useCallback(() => {
        return editUserItemToFormValues(
            {
                user: selectedUser,
                securityOptions,
                salesManagerOptions,
                t
            },
            allCountries
        );
    }, [selectedUser, allCountries]);

    const [formValues, setFormValues] = React.useState<
        IgenericFormValues<Iuser>
    >(itemToFormValues());

    const hasSalesManager = (securityFunctions: Array<string>) => {
        const salesServiceId = constants.securityFunctions.SalesService.id;
        const salesPartsId = constants.securityFunctions.SalesParts.id;
        let salesManager = false;

        securityFunctions.forEach(sf => {
            if (sf === salesServiceId || sf === salesPartsId) {
                salesManager = true;
            }
        });

        return salesManager;
    };

    const canEditSAPValues = () => {
        const manageAllUsersId = constants.securityFunctions.ManageAllUsers.id;
        const currentUserSecurityFunctions = props.user.securityFunctions;

        const hasAllSecurityFunctions = [manageAllUsersId].every(id =>
            currentUserSecurityFunctions.includes(id)
        );

        return hasAllSecurityFunctions;
    };

    const loadFacilitiesOptions = debounce(
        (searchTerm: string, callback: any) => {
            if (searchTerm.length < 3) {
                const facilities = Object.values(facilitiesByID).reduce(
                    (
                        carry: IfacilityWithoutBuildings[],
                        facility: IfacilityWithoutBuildings
                    ) => {
                        if (formValues.countryID === facility.countryID) {
                            carry.push(facility);
                        }

                        return carry;
                    },
                    []
                );
                callback(convertFacillitiesToOptionsWithCountryID(facilities));
                return;
            }
            const usersCountries = (Array.isArray(formValues.userCountries)
                ? formValues.userCountries
                : []) as Ioption[];
            props.searchFacilitiesForAsyncSelect(
                searchTerm,
                usersCountries.map(c => (c.value ? c.value : '')),
                null,
                callback,
                () => {
                    return;
                }
            );
        }
    );

    React.useEffect(() => {
        // Get Security users
        loadCountries(dispatch, allCountries);
        props.getSecurityUsers([constants.securityFunctions.SalesManager.id]);

        return () => {
            props.onChange();
        };
    }, []);

    React.useEffect(() => {
        if (!form) {
            return;
        }

        const countryControl = form.get('countryID');
        const countriesControl = form.get('userCountries');

        countriesControl.valueChanges.subscribe(
            (usersCountries: Icountry[]) => {
                const usersCountry = form.get('countryID')?.value?.value;
                if (
                    usersCountries === null ||
                    !usersCountries.find(c => c.value === usersCountry)
                ) {
                    countryControl.setValue('');
                }
                const facilitiesFrom = form.get('facilities');
                if (usersCountries === null) {
                    facilitiesFrom.setValue([]);
                } else {
                    const facilities: Ifacility[] = facilitiesFrom.value;
                    const newFacilites = facilities.filter(
                        f =>
                            usersCountries.find(
                                c => c.value === f.countryID
                            ) !== undefined
                    );
                    facilitiesFrom.setValue(newFacilites);
                }
            }
        );
        form.valueChanges.subscribe((values: any) => {
            // not sure why we need to keep track of this, the values are already in the form object
            setFormValues({
                ...formValues,
                ...values,
                countryID: values?.countryID?.value
            });
        });

        return () => {
            countriesControl.valueChanges.unsubscribe(() => {
                return;
            });
            countryControl.valueChanges.unsubscribe(() => {
                return;
            });
            form.valueChanges.unsubscribe(() => {
                return;
            });
        };
    }, [form]);

    const fieldConfig = React.useMemo(() => {
        const {
            postalCodes,
            facilities,
            securityFunctions,
            isActive,
            engineerVanID,
            sapEngineerID,
            salesManagerID,
            type,
            countryID,
            brandID,
            userCountries,
            sapObjectID,
            sapPlant,
            sapNonProductiveWorkOrderNumber,
            currency,
            flowUnits,
            powerUnits,
            vacuumUnits,
            tempUnits,
            pressureUnits,
            comments
        } = formValues;

        const disabled = false;
        const languageOptions = FormUtil.convertEnumToOptions(userLanguageEnum);
        const activeCountryOption: Ioption = {
            value: countryID?.toString() || '',
            label: allCountries?.find(c => c.id === countryID)?.name || ''
        };

        let cleanBrandID = '';
        if (
            (brandID as Ioption)?.value !== null &&
            (brandID as Ioption)?.value !== undefined
        ) {
            cleanBrandID = (brandID as Ioption)?.value;
        } else {
            cleanBrandID = (brandID as string) || '';
        }

        let cleanSalesManager: string | Ioption = '';
        if (
            (salesManagerID as Ioption)?.value !== null &&
            (salesManagerID as Ioption)?.value !== undefined &&
            (salesManagerID as Ioption)?.value !== ''
        ) {
            cleanSalesManager = salesManagerID as Ioption;
        } else {
            cleanSalesManager = '';
        }

        const countriesOptions: Ioption[] = allCountries.map(c => ({
            label: c.name,
            value: c.id
        }));

        const tempOptions = FormUtil.convertEnumToOptions(userTempUnits);
        const pressureOptions = FormUtil.convertEnumToOptions(
            userPressureUnits
        );
        const vacuumOptions = FormUtil.convertEnumToOptions(UserVacuumUnits);
        const powerOptions = FormUtil.convertEnumToOptions(UserPowerUnits);
        const flowOptions = FormUtil.convertEnumToOptions(UserFlowUnits);
        const currencyOptions = FormUtil.convertEnumToOptions(UserCurrency);

        // User form, show Brands regardless if they are manufacturers or not
        const brandOptions = FormUtil.convertToOptions(
            props.productInfo.brands
        );

        // Values from formValues comes back as either an Ioption (object) or as a string | number (weird right?)
        let flow =
            typeof flowUnits === 'object'
                ? flowUnits
                : flowOptions.find(x => x.value === flowUnits);
        let temp =
            typeof tempUnits === 'object'
                ? tempUnits
                : tempOptions.find(x => x.value === tempUnits);
        let pressure =
            typeof pressureUnits === 'object'
                ? pressureUnits
                : pressureOptions.find(x => x.value === pressureUnits);
        let vacuum =
            typeof vacuumUnits === 'object'
                ? vacuumUnits
                : vacuumOptions.find(x => x.value === vacuumUnits);
        let power =
            typeof powerUnits === 'object'
                ? powerUnits
                : powerOptions.find(x => x.value === powerUnits);
        let currencyOption =
            typeof currency === 'object'
                ? currency
                : currencyOptions.find(x => x.value === currency);

        let fieldConfigControls: IgenericFieldControls<Iuser> = {
            brandID: {
                render: FormUtil.Select,
                meta: {
                    options: brandOptions,
                    label: 'common:brand',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'brandID',
                    isClearable: true
                },
                formState: {
                    value: brandOptions.find(x => x.value === cleanBrandID),
                    disabled
                }
            },
            userCountries: {
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                render: FormUtil.Select,
                meta: {
                    isMulti: true,
                    options: countriesOptions,
                    label: 'user:countries',
                    colWidth: 12,
                    placeholder: 'manageUserQueue:countrySearchPlaceholder',
                    name: 'countries'
                },
                formState: { value: userCountries, disabled }
            },
            countryID: {
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                render: FormUtil.Select,
                meta: {
                    options: userCountries,
                    label: 'user:primaryCountry',
                    colWidth: 12,
                    placeholder: 'manageUserQueue:countrySearchPlaceholder',
                    name: 'country'
                },
                formState: { value: activeCountryOption, disabled }
            },
            type: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(userTypeEnum),
                    label: 'manageUserQueue:userType',
                    colWidth: 12,
                    placeholder: 'manageUserQueue:facilitySearchPlaceholder',
                    name: 'type',
                    shouldTranslate: true
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: type === userTypeEnum.None ? '' : type,
                    disabled
                }
            },
            engineerVanID: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'manageUser:engineerVanID',
                    colWidth: 6,
                    name: 'engineerVanID',
                    shouldTranslate: true,
                    required: false
                },
                options: {
                    validators: engineerValidators
                },
                formState: {
                    value: engineerVanID,
                    disabled
                }
            },
            sapEngineerID: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'manageUser:sapEngineerID',
                    colWidth: 6,
                    name: 'sapEngineerID',
                    shouldTranslate: true,
                    required: false
                },
                options: {
                    validators: FormUtil.validators.isLessTenDigitAlphaNumeric
                },
                formState: {
                    value: sapEngineerID,
                    disabled: !canEditSAPValues()
                }
            },
            sapObjectID: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'manageUser:SAPobjectID',
                    colWidth: 6,
                    placeholder: 'manageUserQueue:SAPobjectIDSearchPlaceholder',
                    name: 'sapObjectID',
                    required: false
                },
                options: {
                    validators: FormUtil.validators.isNumeric
                },
                formState: {
                    value: sapObjectID,
                    disabled: !canEditSAPValues()
                }
            },
            sapPlant: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'manageUser:SAPPlantID',
                    colWidth: 6,
                    placeholder: 'manageUserQueue:SAPPlantIDSearchPlaceholder',
                    name: 'sapPlant',
                    required: false
                },
                options: {
                    validators: FormUtil.validators.isLessTenDigitAlphaNumeric
                },
                formState: {
                    value: sapPlant,
                    disabled: !canEditSAPValues()
                }
            },
            sapNonProductiveWorkOrderNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'manageUser:SAPNonProductiveWorkOrderNumber',
                    colWidth: 12,
                    placeholder:
                        'manageUserQueue:SAPNonProductiveWorkOrderNumberSearchPlaceholder',
                    name: 'sapNonProductiveWorkOrderNumber',
                    required: false
                },
                options: {
                    validators: FormUtil.validators.isNumeric
                },
                formState: {
                    value: sapNonProductiveWorkOrderNumber,
                    disabled: !canEditSAPValues()
                }
            },
            facilities: {
                render: FormUtil.AsyncSelect,
                meta: {
                    label: 'Facilities',
                    loadOptions: loadFacilitiesOptions,
                    colWidth: 12,
                    placeholder: 'manageUserQueue:facilitySearchPlaceholder',
                    buttonName: 'manageUserQueue:facilityButton',
                    buttonAction: props.toggleEditFacilityModal,
                    isMulti: true,
                    multiValueLabel: MultiValueLabel,
                    multiValueOption: MultiValueOption
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: facilities,
                    disabled
                }
            },
            postalCodes: {
                render: FormUtil.CreatableMultiTextInput,
                meta: {
                    label: 'manageUser:manageUserForm.territories',
                    colWidth: 12,
                    isMulti: true,
                    isClearable: true,
                    menuIsOpen: false,
                    placeholder:
                        'manageUser:manageUserForm.territoryPlaceholder',
                    initialContent: postalCodes,
                    style: { fontSize: '120%' }
                },
                formState: {
                    value: postalCodes,
                    disabled
                }
            },
            salesManagerID: {
                render: FormUtil.Select,
                meta: {
                    label: 'manageUser:manageUserForm.managedBy',
                    placeholder: 'common:searchPlaceholder',
                    options: salesManagerOptions.filter(
                        option => option.value !== selectedUser.id
                    ),
                    colWidth: 12,
                    isMulti: false,
                    isClearable: true,
                    name: 'Managed By'
                },
                formState: {
                    value: cleanSalesManager,
                    disabled
                }
            },
            securityFunctions: {
                render: FormUtil.SelectWithButton,
                meta: {
                    options: securityOptions,
                    label: 'manageUser:securityLabel',
                    colWidth: 12,
                    placeholder: 'manageUser:securitySearchPlaceholder',
                    buttonName: 'manageUser:securityButton',
                    buttonAction: props.toggleSecurityFunctionsModal,
                    isMulti: true,
                    shouldTranslate: true
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: securityFunctions,
                    disabled
                }
            },
            isActive: {
                render: FormUtil.Toggle,
                meta: { label: 'user:active', colWidth: 12 },
                formState: { value: isActive, disabled }
            },
            language: {
                render: FormUtil.Select,
                meta: {
                    options: languageOptions,
                    label: 'common:language',
                    colWidth: 12,
                    placeholder: 'manageUserQueue:facilitySearchPlaceholder',
                    isMulti: false,
                    name: 'language',
                    last: true
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: selectedUser.language
                        ? {
                              value: selectedUser.language,
                              label: userLanguageEnum[selectedUser.language]
                          }
                        : languageOptions[0], // default to english
                    disabled: false
                }
            },
            tempUnits: {
                render: FormUtil.Select,
                meta: {
                    options: tempOptions,
                    label: 'common:tempatureUnits',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'tempUnits'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: { value: temp, disabled }
            },
            pressureUnits: {
                render: FormUtil.Select,
                meta: {
                    options: pressureOptions,
                    label: 'common:pressureUnits',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'pressureUnits'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: { value: pressure, disabled }
            },
            vacuumUnits: {
                render: FormUtil.Select,
                meta: {
                    options: vacuumOptions,
                    label: 'user:vacuumUnits',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'vacuumUnits'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: { value: vacuum, disabled }
            },
            powerUnits: {
                render: FormUtil.Select,
                meta: {
                    options: powerOptions,
                    label: 'user:powerUnits',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'powerUnits'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: { value: power, disabled }
            },
            flowUnits: {
                render: FormUtil.Select,
                meta: {
                    options: flowOptions,
                    label: 'user:flowUnits',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'flowUnits'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: { value: flow, disabled }
            },
            currency: {
                render: FormUtil.Select,
                meta: {
                    options: currencyOptions,
                    label: 'user:currency',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'currency'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: { value: currencyOption, disabled }
            },
            optInGDPR: {
                render: FormUtil.Toggle,
                meta: { label: 'user:GDPR', colWidth: 12 },
                formState: { value: selectedUser.optInGDPR, disabled }
            },
            comments: {
                render: FormUtil.RichTextEditor,
                meta: {
                    label: 'user:comments',
                    colWidth: 12,
                    // placeholder: 'user:comments',
                    name: 'comments',
                    required: false,
                    initialContent: comments ? comments : ''
                },
                formState: {
                    value: comments ? comments : '',
                    disabled: disabled
                }
            }
        };

        // remove the postalCodes control when it is NOT a salesManager

        if (hasSalesManager(selectedUser.securityFunctions) === false) {
            fieldConfigControls = omit(fieldConfigControls, 'salesManagerID');
            fieldConfigControls = omit(fieldConfigControls, 'postalCodes');
        }

        const getBaseControls = () => {
            // needed because of requirement that countries comes before country
            const baseControls = omit(userBaseConfigControls(formValues), [
                'countryID'
            ]);

            // user email is not editable on this page.
            if (
                baseControls &&
                baseControls.email &&
                baseControls.email.formState
            )
                baseControls.email.formState.disabled = true;

            return baseControls;
        };

        const config: FieldConfig = {
            controls: {
                ...getBaseControls(),
                ...fieldConfigControls
            }
        };

        return FormUtil.translateForm(config, t);
    }, [
        formValues.userCountries,
        formValues.countryID,
        allCountries,
        props.productInfo.brandOptions
    ]);

    const formValuesToItem = (): Iuser => {
        const updatedUser = editUserFormValuesToItem({
            updatedValue: form?.value,
            formValues,
            facilitiesByID: props.facilitiesByID,
            selectedUser: props.selectedUser
        });

        const facilities = form?.value?.facilities?.reduce(
            (carry: any, facilityOption: any) => {
                carry.push({
                    id: facilityOption.value,
                    name: facilityOption.label
                });
                return carry;
            },
            []
        );

        return {
            ...updatedUser,
            countryID: form?.value?.countryID?.value,
            facilities
        };
    };

    const handleSubmit = (
        e: React.MouseEvent<HTMLFormElement>,
        shouldApprove?: boolean
    ) => {
        e.preventDefault();

        if (form?.status === 'INVALID') {
            form?.markAsSubmitted();
            toastr.error(
                props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }

        // List of the facility objects tied to the user, can be a mixture of new and existing, the new ones that haven't been saved yet should have a minimum of valud (id) and countryID
        const facilities = form?.value?.facilities;
        let toSubmit = formValuesToItem();
        let anyError = false;

        (Object.keys(toSubmit) as (keyof Iuser)[]).forEach(key => {
            if (key.includes('ID') && toSubmit[key] == '') {
                toSubmit = {
                    ...toSubmit,
                    [key]: null
                };
            }
        });

        // Verify that each country has at least one facility
        toSubmit.userCountries.every((countryID: string) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const countryObject = allCountries.find(c => c.id === countryID)!;

            // Now loop through the assigned facilities, if we don't find one for this country, jump out and throw an error
            let foundFacility = false;
            facilities.every(
                (facility: { value: string; countryID: string }) => {
                    if (facility.countryID === countryObject.id) {
                        foundFacility = true;
                        return false; // break out of the loop, we're good
                    }

                    return true; // keep looping
                }
            );

            // No facility found, throw an error
            if (!foundFacility) {
                anyError = true;
                toastr.error(
                    props.t('toastMessage:invalidFormSubmission'),
                    props.t(
                        'Please select at least 1 facility for ' +
                            countryObject.name
                    ),
                    constants.toastrError
                );
                return false; // break out of the loop, and show error
            }

            return true; // keep looping
        });

        //There is no field to update userStandards in this form. So set userStandards to existing values, or an empty array.
        toSubmit.userStandards = props.user.userStandards || [];

        if (!anyError) {
            props.onSubmit(toSubmit, selectedUser);
            props.closeModal();
        }
    };

    return (
        <div>
            <form
                onSubmit={handleSubmit}
                className={`clearfix beacon-form ${colorButton}`}
            >
                <FormGenerator onMount={setForm} fieldConfig={fieldConfig} />
                <Col xs={12} className="text-right">
                    <Button
                        onClick={() => {
                            setShowUserTrainingForm(true);
                        }}
                        bsStyle={colorButton}
                    >
                        Manage Training
                    </Button>
                    {/* <Button
                        onClick={() => {
                            setShowTrainingModal(true);
                        }}
                        bsStyle={colorButton}
                    >
                        {t('trainingHistory')}
                    </Button> */}
                </Col>
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={props.closeModal}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        bsStyle={colorButton}
                        type="submit"
                        disabled={loading}
                    >
                        {t('save')}
                    </Button>
                </Col>
            </form>
            <EditFacilityModal
                colorButton={colorButton}
                secondModal={true}
                approveMode={false}
                searchForFacility={() => {
                    return;
                }}
                refreshTable={() => {
                    return;
                }}
            />
            <UserTrainingDataModal
                show={showTrainingModal}
                userId={selectedUser?.id}
                secondModal={true}
                onHide={() => {
                    setShowTrainingModal(false);
                    return;
                }}
            />
            <EditUserTrainingForm
                show={showUserTrainingForm && !showTrainingModal}
                selectedUser={selectedUser}
                onHide={() => {
                    setShowUserTrainingForm(false);
                }}
            />
        </div>
    );
};

export default EditUserForm;

import * as React from 'react';

import { Col, DropdownButton, MenuItem } from 'react-bootstrap';

import { TFunction } from 'i18next';
import { deleteFacilityFromContact } from '../../actions/manageFacilityActions';
import { IfacilityContact } from '../../models';
import { RowInfo } from 'react-table';
interface RowInfoEdited extends RowInfo {
    original: IfacilityContact;
}
interface Iprops {
    t: TFunction;
    colorButton: string;
    deleteFacilityFromContact: typeof deleteFacilityFromContact;
    handleSelectContactEdit: (rowInfo: RowInfoEdited) => void;
    addContactLead?: (contact: IfacilityContact) => void;
    rowInfo: RowInfoEdited;
    facilityID: string;
}

export const ContactActionButton = (props: Iprops) => {
    const { t, rowInfo, facilityID, colorButton } = props;
    const onSelect = (eventKey: any) => {
        switch (eventKey) {
            case 1:
                props.deleteFacilityFromContact(
                    rowInfo.original.id,
                    facilityID,
                    t
                );
                break;
            case 2:
                props.handleSelectContactEdit(rowInfo);
                break;
            case 3:
                if (props.addContactLead !== undefined) {
                    props.addContactLead(rowInfo.original);
                }
            default:
                break;
        }
    };
    return (
        <Col xs={4} lg={2}>
            <DropdownButton
                pullRight
                bsStyle={colorButton}
                bsSize="small"
                title={t('facilityContactForm.actions')}
                id="contact-action-button"
                onSelect={onSelect}
            >
                {props.addContactLead !== undefined && (
                    <MenuItem eventKey={3}>
                        {t('facilityContactForm.select')}
                    </MenuItem>
                )}
                <MenuItem eventKey={2}>
                    {t('facilityContactForm.edit')}
                </MenuItem>
                <MenuItem eventKey={1}>
                    {t('facilityContactForm.delete')}
                </MenuItem>
            </DropdownButton>
        </Col>
    );
};

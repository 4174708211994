import * as React from 'react';

import {
    GFCourse,
    ImanageTrainingProgress,
    ImanageTrainingReducer,
    Ioption,
    ItableFiltersReducer,
    Itile,
    Iuser
} from '../../models';
import ReactTable, { FinalState, RowInfo, SortingRule } from 'react-table';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    getManageTraining,
    setTableFilter
} from '../../actions/manageTrainingActions';

import { Banner } from '../common/Banner';
import { FieldConfig } from 'react-reactive-form';
import { FormUtil } from '../common/FormUtil';
/*
 * The New User Manage
 */
import { RouteComponentProps } from 'react-router-dom';
import SearchTableForm from '../common/SearchTableForm';
import { TableUtil } from '../common/TableUtil';
import { closeAllModals } from '../../actions/commonActions';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { emptyTile } from '../../reducers/initialState';
import { isEmpty } from 'lodash';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

import More from '../../images/icons/More.svg';
import Certificate from '../../images/icons/Certificate.svg';
import moment from 'moment';
import TrainingDetailModal from './TrainingDetailModal';
import { securityFunctions } from '../../constants/securityFunctions';
import { loadCourses } from '../../actions/trainingActions';

interface Iprops extends RouteComponentProps<any> {
    // Add your regular properties here
    user: Iuser;
    tableFilters: ItableFiltersReducer;
    tableData: ImanageTrainingProgress[];
    manageTraining: ImanageTrainingReducer;
    loading: boolean;
    coursesByID: { [key: string]: GFCourse };
}

interface IdispatchProps {
    // Add your dispatcher properties here
    getManageTraining: typeof getManageTraining;
    setTableFilter: typeof setTableFilter;
    closeAllModals: typeof closeAllModals;
    loadCourses: typeof loadCourses;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    columns: any[];
    canManageTraining: boolean;
    courseOptions: Ioption[];
    expanded: { [key: string]: boolean };
    searchFieldConfig: FieldConfig;
}

class ManageTraining extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    public buttonInAction = false;
    private setTableFilterTimeout: any;
    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: null,
            currentTile: emptyTile,
            columns: [],
            canManageTraining: false,
            courseOptions: [],
            expanded: {},
            searchFieldConfig: { controls: {} }
        };
    }
    componentDidMount() {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname),
            columns: this.setColumns()
        });
        // refresh the ManageTraining every time the component mounts
        this.props.loadCourses(this.props.user);
        this.props.getManageTraining();
        this.setState({ searchFieldConfig: this.buildFieldConfig() });
        if (
            constants.hasSecurityFunction(
                this.props.user,
                [
                    securityFunctions.ManageAllTraining.id,
                    securityFunctions.ManageEmployeeTraining.id
                ],
                { matchAll: true }
            )
        )
            this.setState({ canManageTraining: true });

        if (this.props.coursesByID) {
            const courseOptions = this.convertCourseIDsToOptions(
                this.props.coursesByID
            );
            this.setState({
                courseOptions,
                searchFieldConfig: this.buildFieldConfig()
            });
        }
    }

    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        // automatically get data every time a fitler changes
        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            this.props.getManageTraining();
            this.setState({ searchFieldConfig: this.buildFieldConfig() });
            // scroll top every time a filter changes
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }

        if (
            JSON.stringify(prevProps.coursesByID) !==
            JSON.stringify(this.props.coursesByID)
        ) {
            const courseOptions = this.convertCourseIDsToOptions(
                this.props.coursesByID
            );

            this.setState({
                courseOptions,
                searchFieldConfig: this.buildFieldConfig()
            });
        }

        const els = document.querySelectorAll('.non-expandable');

        els.forEach(el => {
            const element = el as HTMLDivElement;
            if (element.style.cursor === 'pointer')
                element.style.cursor = 'default';
        });

        if (
            prevProps.loading !== this.props.loading ||
            JSON.stringify(prevProps.tableData) !==
                JSON.stringify(this.props.tableData)
        ) {
            this.setState({ searchFieldConfig: this.buildFieldConfig() });
        }
    }

    componentWillUnmount() {
        this.props.closeAllModals();
    }

    buildFieldConfig = () => {
        const { courseID, search } = this.props.tableFilters;
        const courseOption = this.state.courseOptions?.find(
            opt => opt.value === courseID
        );
        const disabled = this.props.loading;

        return {
            controls: {
                search: {
                    render: FormUtil.TextInputWithoutValidation,
                    meta: {
                        label: 'common:search',
                        colWidth: 4,
                        type: 'text',
                        placeholder: 'searchPlaceholder',
                        defaultValue: this.props.tableFilters.search
                    },
                    formState: {
                        value: search || '',
                        disabled
                    }
                },
                courseID: {
                    render: FormUtil.SelectWithoutValidation,
                    meta: {
                        label: 'manageTraining:courseName',
                        options: this.state.courseOptions,
                        colWidth: 4,
                        type: 'select',
                        isClearable: true
                    },
                    formState: {
                        value: courseOption,
                        disabled
                    }
                },
                isCourseCompleted: {
                    render: FormUtil.Toggle,
                    meta: {
                        label: 'manageTraining:courseCompleted',
                        colWidth: 2,
                        innerStyle: {
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            height: '48px',
                            paddingTop: '24px'
                        }
                    },
                    formState: {
                        value:
                            this.props.tableFilters?.isCourseCompleted || false,
                        disabled
                    }
                }
            }
        };
    };

    convertCourseIDsToOptions = (coursesByID: {
        [key: string]: GFCourse;
    }): Ioption[] =>
        Object.values(coursesByID)
            .map(course => ({
                label: course.name,
                value: course.id
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

    openDetailModal = (
        evt: React.MouseEvent<HTMLAnchorElement>,
        selectedRow: any
    ) => {
        evt.preventDefault();
        this.setState({ selectedRow });
    };

    /*
     * Set Columns sets columns to state
     * setting columns here in order to reset them if and after we receive customers
     */
    setColumns = () => {
        return TableUtil.translateHeaders(
            [
                {
                    id: 'expander-toggle',
                    expander: true,
                    Expander: row =>
                        row.original.expandable
                            ? TableUtil.expanderToggle(row)
                            : null,
                    style: {
                        cursor: 'pointer',
                        textAlign: 'left',
                        userSelect: 'none'
                    }
                },
                {
                    Header: 'userName',
                    accessor: 'userName'
                },
                {
                    Header: 'courseName',
                    accessor: 'courseName'
                },
                {
                    Header: 'progress',
                    accessor: 'progress'
                },
                {
                    Header: 'credentialValidFrom',
                    Cell: ({ original: { credentialIssueDate } }) => (
                        <span>
                            {credentialIssueDate
                                ? moment(
                                      credentialIssueDate,
                                      'YYYY-MM-DD'
                                  ).format('DD-MMM-YY')
                                : '--'}
                        </span>
                    )
                },
                {
                    Header: 'credentialValidUntil',
                    Cell: ({ original: { credentialValidUntil } }) => (
                        <span>
                            {credentialValidUntil
                                ? moment(
                                      credentialValidUntil,
                                      'YYYY-MM-DD'
                                  ).format('DD-MMM-YY')
                                : '--'}
                        </span>
                    )
                },
                {
                    Header: 'results',
                    Cell: ({ original: { score } }) => (
                        <span>{score ? `${score}%` : '--'}</span>
                    ),
                    width: 180
                },
                {
                    Header: 'brand',
                    Cell: ({ original }) => (
                        <span>{original?.brandName || ''}</span>
                    )
                },
                {
                    Header: '',
                    Cell: ({ original }) => (
                        <span
                            className="text-right"
                            style={{ paddingRight: '24px' }}
                        >
                            {original.certificateUrl && (
                                <a
                                    href={original.certificateUrl}
                                    target="_blank"
                                    style={{ paddingRight: '24px' }}
                                >
                                    <img src={Certificate} />
                                </a>
                            )}
                            <a
                                href="/"
                                onClick={evt =>
                                    this.openDetailModal(evt, original)
                                }
                            >
                                <img src={More} />
                            </a>
                        </span>
                    ),
                    width: 180
                }
            ],
            this.props.t
        );
    };

    /*
     * (reusable)
     * get the next or previous page of data.  the table is 0 indexed but the API is not
     */
    onPageChange = (page: number) => {
        const newPage = page + 1;
        this.props.setTableFilter({ page: newPage });
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'customer':
                this.props.setTableFilter({ page: 1 });
                break;
            case 'search':
                clearTimeout(this.setTableFilterTimeout);
                this.setTableFilterTimeout = setTimeout(() => {
                    this.props.setTableFilter({ search: value, page: 1 }); // this causes performance issues so we use a rudamentary debounce
                }, constants.tableSearchDebounceTime);
                break;
            case 'courseID':
                this.props.setTableFilter({ courseID: value?.value });
                break;
            case 'isCourseCompleted':
                this.props.setTableFilter({ isCourseCompleted: value });
            default:
                break;
        }
    };

    /*
     * (reusable)
     * set the sorted changes to redux
     */
    onSortedChanged = (
        newSorted: SortingRule[],
        column: any,
        shiftKey: boolean
    ) => {
        this.props.setTableFilter({ sorted: newSorted });
        this.setState({ selectedRow: {} });
    };

    getSubColumns = () => {
        const existingColumns = this.setColumns();
        existingColumns.shift();
        existingColumns.unshift({});
        return existingColumns;
    };

    onExpandedChange = (row: { [key: string]: Object }) => {
        const [index] = Object.keys(row);
        const { expanded } = this.state;

        expanded[index] = !expanded[index];
        this.setState({ expanded: { ...expanded } });
    };

    render() {
        const { t } = this.props;
        return (
            <div className="training-manage">
                <Banner
                    title={t('bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                />
                {this.state.canManageTraining && (
                    <SearchTableForm
                        fieldConfig={this.state.searchFieldConfig}
                        handleSubmit={this.props.getManageTraining}
                        loading={this.props.loading}
                        colorButton={
                            constants.colors[
                                `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                            ]
                        }
                        t={this.props.t}
                        subscribeValueChanges={true}
                        onValueChanges={this.onSearchValueChanges}
                    />
                )}
                <ReactTable
                    data={this.props.tableData}
                    onSortedChange={this.onSortedChanged}
                    columns={this.state.columns}
                    pageSize={
                        this.props.tableData.length >= 10
                            ? this.props.tableData.length
                            : 10
                    }
                    page={this.props.tableFilters.page - 1}
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    pages={this.props.manageTraining.totalPages}
                    showPageSizeOptions={false}
                    className={`beacon-table -highlight ${this.state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    sortable={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                    SubComponent={x =>
                        x.original.expandable ? (
                            <ReactTable
                                data={x.original.subRows}
                                columns={this.getSubColumns()}
                                sortable={false}
                                minRows={0}
                                resizable={false}
                                showPagination={false}
                            />
                        ) : null
                    }
                    onExpandedChange={this.onExpandedChange}
                    expanded={this.state.expanded}
                    getTdProps={(_: FinalState, rowInfo: RowInfo | undefined) =>
                        rowInfo?.original.expandable
                            ? {}
                            : { class: 'rt-td rt-expandable non-expandable' }
                    }
                />
                <TrainingDetailModal
                    show={!isEmpty(this.state.selectedRow)}
                    onHide={() => this.setState({ selectedRow: {} })}
                    userData={this.state.selectedRow}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const {
        user,
        manageTraining,
        training: { coursesByID }
    } = state;

    const tableData: (ImanageTrainingProgress & {
        expandable?: boolean;
        subRows?: ImanageTrainingProgress[];
    })[] = [];

    if (manageTraining.trainingProgress)
        Object.values(manageTraining.trainingProgress).forEach(progress => {
            const foundRow = tableData.find(
                row =>
                    row.courseID === progress.courseID &&
                    row.userID === progress.userID
            );

            if (foundRow) {
                if (foundRow.subRows && foundRow.subRows.length)
                    foundRow.subRows.push(progress);
                else {
                    foundRow.expandable = true;
                    foundRow.subRows = [progress];
                }
            } else tableData.push(progress);
        });

    tableData.sort((a, b) => a.userName.localeCompare(b.userName));

    return {
        user,
        coursesByID,
        manageTraining,
        loading: selectIsLoading(state),
        tableData,
        tableFilters: state.manageTraining.tableFilters
    };
};

export default withTranslation('manageTraining')(
    connect(mapStateToProps, {
        getManageTraining,
        setTableFilter,
        closeAllModals,
        loadCourses
    })(ManageTraining)
);

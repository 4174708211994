import * as Highcharts from 'highcharts';
import * as React from 'react';

import { ControlLabel, FormGroup } from 'react-bootstrap';
import {
    IanalyticsInspection,
    Ioption,
    ImainCategory,
    Ifacility,
    Iuser
} from '../../models';

import { ControlComponent } from '../common/FormUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HighchartsReact from 'highcharts-react-official';
import { Link, RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import { TFunction } from 'i18next';
import { constants } from '../../constants/constants';
import {
    getProductInfo,
    setTableFilter
} from '../../actions/manageInventoryActions';
import { initialAnalyticsInspection } from '../../reducers/initialState';
// import { mockAnalyticsInspection } from '../../reducers/mockState';
import moment from 'moment';
import { orderBy } from 'lodash';
import { measurementPointResultStatusTypesEnum } from '../../models-enums';
import { mockAnalyticsInspection } from './mockAnalytics';

interface IchartItem {
    y: number;
    name: string;
}

interface IchartItemWithColor extends IchartItem {
    color: string;
}

interface Iprops {
    t: TFunction;
    mainCategories: { [key: string]: ImainCategory };
    mainCategoryOptions: Ioption[];
    getProductInfo: typeof getProductInfo;
    inspection: IanalyticsInspection[];
    isDemoMode: boolean;
    selectedFacility: Ifacility;
    setTableFilter: typeof setTableFilter;
    user: Iuser;
}
interface Istate {
    selectedCategory?: Ioption;
    chartOptions: Partial<Highcharts.Options>;
    categoryOptions: Ioption[];
    passed: number;
    failed: number;
    repaired: number;
    cannotComplete: number;
    notTested: number;
}

export default class InstallBaseAnalytics extends React.PureComponent<
    Iprops & RouteComponentProps<{}>,
    Istate
> {
    constructor(props: Iprops & RouteComponentProps<{}>) {
        super(props);
        this.state = {
            selectedCategory: undefined,
            chartOptions: this.options,
            categoryOptions: this.props.mainCategoryOptions,
            passed: 0,
            failed: 0,
            repaired: 0,
            notTested: 0,
            cannotComplete: 0
        };
    }
    componentDidMount() {
        if (
            this.props.mainCategoryOptions.length === 0 &&
            constants.hasSecurityFunction(this.props?.user, [
                constants.securityFunctions.ManageProducts.id
            ])
        ) {
            this.props.getProductInfo();
        }
        this.handleUpdatedData();
    }
    componentDidUpdate(prevProps: Iprops) {
        if (
            prevProps.mainCategoryOptions.length !==
            this.props.mainCategoryOptions.length
        ) {
            this.handleUpdatedData();
        }
        if (
            JSON.stringify(prevProps.inspection) !==
            JSON.stringify(this.props.inspection)
        ) {
            this.handleUpdatedData();
        }
    }
    getQuantityFromStatus = (status: string, data: any[], total: number) => {
        const element = data.find(
            d => d.name?.toLowerCase() === status.toLocaleLowerCase()
        );
        if (element) {
            return Math.round((element.y / 100) * total);
        }
        return 0;
    };

    handleUpdatedData = () => {
        const { inspection, mainCategoryOptions } = this.props;
        let categoryOptions = this.props.mainCategoryOptions;
        // load the data from the selected category
        if (inspection?.length) {
            // filter the category options down to ones that actually have data in them
            categoryOptions = mainCategoryOptions.filter(option => {
                const category = inspection?.find(
                    item => item.mainCategoryID === option.value
                );
                if (category && category.total) {
                    return true;
                } else {
                    return false;
                }
            });
            const analyticsInspection = this.getSelectedAnalyticsInspection();
            const { data, total } = analyticsInspection;
            this.setState({
                failed: this.getQuantityFromStatus('Fail', data, total),
                passed: this.getQuantityFromStatus('Pass', data, total),
                notTested: this.getQuantityFromStatus(
                    'Not Tested',
                    data,
                    total
                ),
                repaired: this.getQuantityFromStatus('Repaired', data, total),
                cannotComplete: this.getQuantityFromStatus(
                    'Cannot Complete',
                    data,
                    total
                ),
                chartOptions: {
                    series: [{ data: analyticsInspection.data }],
                    title: { text: `${analyticsInspection.total}` }
                } as any,
                categoryOptions
            });
        } else {
            this.setState({
                chartOptions: {
                    series: [{ data: [] }],
                    title: { text: `0` }
                } as any,
                categoryOptions: this.props.mainCategoryOptions,
                failed: this.getQuantityFromStatus('Fail', [], 0),
                passed: this.getQuantityFromStatus('Pass', [], 0),
                notTested: this.getQuantityFromStatus('Not Tested', [], 0),
                repaired: this.getQuantityFromStatus('Repaired', [], 0),
                cannotComplete: this.getQuantityFromStatus(
                    'Cannot Complete',
                    [],
                    0
                )
            });
        }
    };

    navigateToPass = () => {
        const passT = this.props.t(
            `manageInventory:${
                measurementPointResultStatusTypesEnum[
                    measurementPointResultStatusTypesEnum.resultStatusPass
                ]
            }`
        );

        this.props.setTableFilter({
            status: {
                value: `${measurementPointResultStatusTypesEnum.resultStatusPass}`,
                label: passT
            },
            page: 0,
            mainCategory: this.state.selectedCategory
        });
        this.props.history.push('/inventory');
    };

    navigateToFailed = () => {
        const failT = this.props.t(
            `manageInventory:${
                measurementPointResultStatusTypesEnum[
                    measurementPointResultStatusTypesEnum.resultStatusFail
                ]
            }`
        );

        this.props.setTableFilter({
            status: {
                value: `${measurementPointResultStatusTypesEnum.resultStatusFail}`,
                label: failT
            },
            page: 0,
            mainCategory: this.state.selectedCategory
        });
        this.props.history.push('/inventory');
    };

    navigateToRepaired = () => {
        const repairedT = this.props.t(
            `manageInventory:${
                measurementPointResultStatusTypesEnum[
                    measurementPointResultStatusTypesEnum.resultStatusRepaired
                ]
            }`
        );
        this.props.setTableFilter({
            status: {
                value: `${measurementPointResultStatusTypesEnum.resultStatusRepaired}`,
                label: repairedT
            },
            page: 0,
            mainCategory: this.state.selectedCategory
        });
        this.props.history.push('/inventory');
    };

    navigateToNotTested = () => {
        const notTestedT = this.props.t(
            `manageInventory:${
                measurementPointResultStatusTypesEnum[
                    measurementPointResultStatusTypesEnum.resultStatusNotTested
                ]
            }`
        );

        this.props.setTableFilter({
            status: {
                value: `${measurementPointResultStatusTypesEnum.resultStatusNotTested}`,
                label: notTestedT
            },
            page: 0,
            mainCategory: this.state.selectedCategory
        });
        this.props.history.push('/inventory');
    };

    navigateToCannotComplete = () => {
        const cannotCompleteT = this.props.t(
            `manageInventory:${
                measurementPointResultStatusTypesEnum[
                    measurementPointResultStatusTypesEnum
                        .resultStatusCannotComplete
                ]
            }`
        );
        this.props.setTableFilter({
            status: {
                value: `${measurementPointResultStatusTypesEnum.resultStatusCannotComplete}`,
                label: cannotCompleteT
            },
            page: 0,
            mainCategory: this.state.selectedCategory
        });
        this.props.history.push('/inventory');
    };

    getSelectedAnalyticsInspection = (): IanalyticsInspection => {
        const { inspection, isDemoMode } = this.props;

        let analyticsInspection = initialAnalyticsInspection;
        if (inspection?.length) {
            const { selectedCategory } = this.state;
            const selectedCategoryWithoutType = selectedCategory as any;
            if (
                selectedCategoryWithoutType &&
                selectedCategoryWithoutType.value
            ) {
                analyticsInspection =
                    inspection?.find(
                        item =>
                            item.mainCategoryID ===
                            selectedCategoryWithoutType.value
                    ) || initialAnalyticsInspection;
            } else {
                analyticsInspection =
                    inspection?.find(
                        item =>
                            item.mainCategoryID ===
                            '00000000-0000-0000-0000-000000000000'
                    ) || initialAnalyticsInspection;
            }
        }
        if (isDemoMode) {
            analyticsInspection = mockAnalyticsInspection;
        }

        const updatedData: IchartItemWithColor[] = analyticsInspection.data.map(
            (i: IchartItem) => {
                const item = i;
                if (item.name) {
                    item.name = this.props.t(`dashboardAnalytics:${item.name}`); // the item name comes from the API as camelCase and then we translate it
                }

                // add the correct color
                return {
                    ...item,
                    color:
                        constants.installBaseStatusColorLookup[
                            item.name as keyof typeof constants.installBaseStatusColorLookup
                        ]
                };
            }
        );
        return { ...analyticsInspection, data: updatedData };
    };

    options: Highcharts.Options = {
        title: {
            text: '0',
            align: 'center',
            verticalAlign: 'middle',
            y: 15,
            margin: 5,
            style: { color: '#000', fontSize: '28px', fontWeight: 'bold' }
            // text: this.props.t('installBaseAnalytics'),
        },
        subtitle: {
            text: 'assets',
            align: 'center',
            verticalAlign: 'middle',
            y: 25,
            style: { textTransform: 'capitalize' }
        },
        tooltip: {
            formatter() {
                return `${this.point.name}`;
                // return `${this.point.name}: ${this.y}%`;
            }
        },
        chart: {
            type: 'pie',
            backgroundColor: '',
            height: 200,
            spacingTop: 0,
            spacingBottom: 0
        },
        // attempted to make the chart animation less jarring.  it did not work
        // plotOptions: {
        //   pie: {states: {hover:{ animation: {duration: 5000}}}}
        // },
        credits: {
            enabled: false
        },
        legend: {
            itemStyle: {
                color: constants.colors.darkGreyText,
                fontSize: '.9em'
            }
        },
        plotOptions: {
            pie: {
                size: 180
            }
        },
        series: [
            {
                type: 'pie',
                dataLabels: { enabled: false },
                innerSize: '55%',
                data: [],
                cursor: 'pointer',
                events: {
                    // TODO - can this be refactored to use the functions that the onClicks in the spans use?
                    click: event => {
                        // when clicking on a portion of the donut, use the name to match it with a result status
                        const name = event.point.name;
                        const notTestedT = this.props.t(
                            `manageInventory:${
                                measurementPointResultStatusTypesEnum[
                                    measurementPointResultStatusTypesEnum
                                        .resultStatusNotTested
                                ]
                            }`
                        );
                        const cannotCompleteT = this.props.t(
                            `manageInventory:${
                                measurementPointResultStatusTypesEnum[
                                    measurementPointResultStatusTypesEnum
                                        .resultStatusCannotComplete
                                ]
                            }`
                        );
                        const passT = this.props.t(
                            `manageInventory:${
                                measurementPointResultStatusTypesEnum[
                                    measurementPointResultStatusTypesEnum
                                        .resultStatusPass
                                ]
                            }`
                        );
                        const failT = this.props.t(
                            `manageInventory:${
                                measurementPointResultStatusTypesEnum[
                                    measurementPointResultStatusTypesEnum
                                        .resultStatusFail
                                ]
                            }`
                        );
                        const repairedT = this.props.t(
                            `manageInventory:${
                                measurementPointResultStatusTypesEnum[
                                    measurementPointResultStatusTypesEnum
                                        .resultStatusRepaired
                                ]
                            }`
                        );

                        switch (name) {
                            case notTestedT:
                                this.navigateToNotTested();
                                break;
                            case cannotCompleteT:
                                this.navigateToCannotComplete();
                                break;
                            case passT:
                                this.navigateToPass();
                                break;
                            case failT:
                                this.navigateToFailed();
                                break;
                            case repairedT:
                                this.navigateToRepaired();
                                break;
                            default:
                                console.error(
                                    'unknown result status type clicked',
                                    name,
                                    notTestedT
                                );
                                break;
                        }
                    }
                }
            }
        ]
    };

    onSelect = (value: any) => {
        this.setState(
            {
                selectedCategory: value
            },
            () => {
                this.handleUpdatedData();
            }
        );
    };

    // for US show one version, for UK show a different version
    getTitle = () => {
        if (
            this.props.selectedFacility.standardID ===
            constants.defaultStandardUS
        ) {
            return this.props.t('installBaseAnalytics');
        } else return this.props.t('inspectionStatus.status');
    };

    render() {
        const selectedAnalyticsInspection = this.getSelectedAnalyticsInspection();
        const { t } = this.props;
        const bigNumberStyle: React.CSSProperties = {
            color: '#3C3C3C',
            fontSize: '17px',
            fontWeight: 'bold',
            margin: '5px 0 0',
            cursor: 'pointer'
        };

        return (
            <div className="form-group install-base-analytics analytics-item">
                <div style={{ position: 'relative' }}>
                    <div
                        className="status-container"
                        style={{ paddingRight: '0', position: 'unset' }}
                    >
                        <div className="left">
                            <Link
                                to="/assetStatus"
                                style={{
                                    fontSize: '1.7em'
                                }}
                                title={t('moreInfo')}
                            >
                                <h4
                                    style={{
                                        color: '#000',
                                        display: 'inline',
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        margin: '0 10px 0 0',
                                        overflowWrap: 'break-word',
                                        textTransform: 'uppercase',
                                        wordWrap: 'break-word'
                                    }}
                                >
                                    {this.getTitle()}
                                </h4>
                            </Link>
                            <Link
                                to="/assetStatus"
                                style={{
                                    fontSize: '14px',
                                    position: 'absolute',
                                    right: 0,
                                    top: 0,
                                    zIndex: 4
                                }}
                                title={t('moreInfo')}
                                data-type="icon"
                            >
                                <FontAwesomeIcon
                                    icon={['far', 'info-circle']}
                                    color="#A1ACB3"
                                    style={{
                                        fontSize: '14px',
                                        height: '14px',
                                        width: '14px'
                                    }}
                                />
                            </Link>
                            <div>
                                <div
                                    className="analytics-info"
                                    style={{ marginTop: '20px' }}
                                >
                                    <span
                                        style={{
                                            color:
                                                constants.colors.darkGreyText,
                                            fontSize: '1.4em',
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {t('inspectionStatus.lastInspection')}
                                    </span>
                                    {selectedAnalyticsInspection.jobDate
                                        .length !== 0 && (
                                        <h1 style={bigNumberStyle}>
                                            {moment
                                                .utc(
                                                    selectedAnalyticsInspection.jobDate
                                                )
                                                .local(true)
                                                .format('DD-MMM-YY')}
                                        </h1>
                                    )}
                                    {selectedAnalyticsInspection.jobDate
                                        .length === 0 && (
                                        <h4 style={bigNumberStyle}>
                                            {t('never')}
                                        </h4>
                                    )}
                                </div>
                            </div>
                            <form className="beacon-form dashboard-form">
                                <FormGroup bsSize="sm">
                                    <ControlLabel
                                        style={{
                                            margin: '0 0 1rem',
                                            textTransform: 'uppercase'
                                        }}
                                    >
                                        {this.props.t('category')}
                                    </ControlLabel>
                                    <Select
                                        options={orderBy(
                                            this.state.categoryOptions,
                                            option => option.label.toLowerCase()
                                        )}
                                        className="category-select"
                                        components={{
                                            Control: ControlComponent
                                        }}
                                        placeholder={t('all')}
                                        isMulti={false}
                                        classNamePrefix="react-select"
                                        name={'category-select'}
                                        isClearable={true}
                                        onChange={this.onSelect}
                                        value={this.state.selectedCategory}
                                    />
                                </FormGroup>
                            </form>
                        </div>
                        <div className="right">
                            <div
                                style={{
                                    paddingLeft: 20,
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                {this.state.passed > 0 && (
                                    <span
                                        onClick={this.navigateToPass.bind(this)}
                                        style={bigNumberStyle}
                                    >
                                        {' '}
                                        <span
                                            style={{
                                                textTransform: 'uppercase',
                                                color:
                                                    constants
                                                        .installBaseStatusColorLookup[
                                                        'Pass'
                                                    ]
                                            }}
                                        >
                                            {this.props.t('pass')}
                                        </span>{' '}
                                        = {this.state.passed}{' '}
                                        {this.props.t('assets')}
                                    </span>
                                )}
                                {this.state.failed > 0 && (
                                    <span
                                        onClick={this.navigateToFailed.bind(
                                            this
                                        )}
                                        style={bigNumberStyle}
                                    >
                                        {' '}
                                        <span
                                            style={{
                                                textTransform: 'uppercase',
                                                color:
                                                    constants
                                                        .installBaseStatusColorLookup[
                                                        'Fail'
                                                    ]
                                            }}
                                        >
                                            {this.props.t('fail')}
                                        </span>{' '}
                                        = {this.state.failed}{' '}
                                        {this.props.t('assets')}
                                    </span>
                                )}
                                {this.state.repaired > 0 && (
                                    <span
                                        onClick={this.navigateToRepaired.bind(
                                            this
                                        )}
                                        style={bigNumberStyle}
                                    >
                                        {' '}
                                        <span
                                            style={{
                                                textTransform: 'uppercase',
                                                color:
                                                    constants
                                                        .installBaseStatusColorLookup[
                                                        'Repaired'
                                                    ]
                                            }}
                                        >
                                            {this.props.t('repaired')}{' '}
                                        </span>{' '}
                                        = {this.state.repaired}{' '}
                                        {this.props.t('assets')}
                                    </span>
                                )}
                                {this.state.notTested > 0 && (
                                    <span
                                        onClick={this.navigateToNotTested.bind(
                                            this
                                        )}
                                        style={bigNumberStyle}
                                    >
                                        {' '}
                                        <span
                                            style={{
                                                textTransform: 'uppercase',
                                                color:
                                                    constants
                                                        .installBaseStatusColorLookup[
                                                        'Not Tested'
                                                    ]
                                            }}
                                        >
                                            {this.props.t('notTested')}{' '}
                                        </span>{' '}
                                        = {this.state.notTested}{' '}
                                        {this.props.t('assets')}
                                    </span>
                                )}
                                {this.state.cannotComplete > 0 && (
                                    <span
                                        onClick={this.navigateToCannotComplete.bind(
                                            this
                                        )}
                                        style={bigNumberStyle}
                                    >
                                        {' '}
                                        <span
                                            style={{
                                                textTransform: 'uppercase',
                                                color:
                                                    constants
                                                        .installBaseStatusColorLookup[
                                                        'Cannot Complete'
                                                    ]
                                            }}
                                        >
                                            {this.props.t('cannotComplete')}{' '}
                                        </span>{' '}
                                        = {this.state.cannotComplete}{' '}
                                        {this.props.t('assets')}
                                    </span>
                                )}
                            </div>
                            <div
                                className="analytics-graph"
                                style={{
                                    minWidth: '200px',
                                    padding: '0'
                                }}
                            >
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={this.state.chartOptions}
                                    {...this.props}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

import * as types from './actionTypes';

import { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { ThunkResult, IfacilityWithoutBuildings, Ifacility } from '../models';

import API from '../constants/apiEndpoints';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { constants } from '../constants/constants';
import { msalFetch } from '../components/auth/Auth-Utils';
import { toastr } from 'react-redux-toastr';
import {
    convertFacilityOptions,
    convertFacillitiesToOptionsWithCountryID
} from '../reducers/facilitiesReducer';
import { isEmpty } from 'lodash';

export interface FacilitiesSearchParams {
    name?: string;
    address?: string;
    postalCode?: string;
}

const uuidv4 = require('uuid/v4');

export const closeAllModals = () => ({
    type: types.CLOSE_ALL_MODALS
});
export const toggleEditFacilityModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_FACILITY
});
export const toggleSideMenu = () => ({
    type: types.TOGGLE_MODAL_SIDE_MENU
});

export function getFacilitiesByCountry(
    filterParams: FacilitiesSearchParams
): ThunkResult<any> {
    const cleanParams = (obj: FacilitiesSearchParams): FacilitiesSearchParams =>
        Object.fromEntries(
            Object.entries(obj).filter(
                ([_, val]) => val !== null && val !== undefined
            )
        );
    const params = isEmpty(filterParams)
        ? undefined
        : cleanParams(filterParams);

    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params
        };

        const url = API.GET.facility.search;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>): { hasFacilities: boolean } => {
                dispatch(endAjaxCall());
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.GET_FACILITIES_SUCCESS,
                        facilities: data.data
                    });
                    if (data.data.length === 0) {
                        return { hasFacilities: false };
                    } else {
                        return { hasFacilities: true };
                    }
                }
            })
            .catch((error: any) => {
                dispatch(endAjaxCall());
                dispatch({
                    type: types.GET_FACILITIES_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get facilities search');
                console.error(error);
            });
    };
}

export function searchFacilityQueueForAsyncSelect(
    name: string | undefined,
    countryId: string | undefined,
    callback: any,
    saveFacilities: any
): ThunkResult<any> {
    return (dispatch, getState) => {
        let url: string;
        let params: any = { name };

        // API is expecting a Guid, don't send an empty string
        if (countryId) {
            params = { ...params, countryId };
        }

        let data: any;
        let method: Method = 'get';

        url = API.GET.facility.GetFacilityQueue;

        const axiosOptions: AxiosRequestConfig = {
            method,
            params,
            data
        };

        if (!name && name !== undefined) {
            return;
        }

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                }
                saveFacilities(data.data);
                callback(convertFacillitiesToOptionsWithCountryID(data.data));
            })
            .catch((error: any) => {
                constants.handleError(error, 'get facilities search');
                console.error(error);
            });
    };
}

export function searchFacilitiesForAsyncSelect(
    name: string | undefined,
    countryId: string | null | string[],
    quantityOfAssets: number | null,
    callback: any,
    saveFacilities: any,
    page?: number,
    callbackWithPagination?: any
): ThunkResult<any> {
    return (dispatch, getState) => {
        let url: string;
        const params = { name, quantityOfAssets, page: page || 1 };
        let data: any;
        let method: Method = 'get';

        if (countryId !== null && countryId !== '' && countryId !== undefined) {
            data = Array.isArray(countryId) ? countryId : [countryId];
            url = API.GET.facility.searchByCountries;
            method = 'put';
        } else {
            url = API.GET.facility.search;
        }

        const axiosOptions: AxiosRequestConfig = {
            method,
            params,
            data
        };

        if (!name && name !== undefined) {
            return;
        }

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch(endAjaxCall());
                if (!data.data) {
                    throw new Error('missing data');
                }
                saveFacilities(data.data.result);
                callback(
                    convertFacillitiesToOptionsWithCountryID(data.data.result)
                );
                if (
                    callbackWithPagination &&
                    typeof callbackWithPagination === 'function'
                )
                    callbackWithPagination(data.data);
            })
            .catch((error: any) => {
                dispatch(endAjaxCall());
                constants.handleError(error, 'get facilities search');
                console.error(error);
            });
    };
}

export function getFacility(facilityID: string): Promise<void | Ifacility> {
    const axiosOptions: AxiosRequestConfig = {
        method: 'get'
    };

    const url = `${API.GET.facility.getbyid}/${facilityID}`;
    return msalFetch(url, axiosOptions)
        .then((data: AxiosResponse<any>) => {
            if (!data.data) {
                throw new Error('missing data');
            } else {
                return data.data as Ifacility;
            }
        })
        .catch((error: any) => {
            constants.handleError(error, 'get facility locations');
            console.error(error);
        });
}

export function addFacility(
    facility: IfacilityWithoutBuildings
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const newID = uuidv4();
        const contractWithNewFacilityID = facility.contract
            ? { ...facility.contract, facilityID: newID }
            : null;
        const data = {
            ...facility,
            id: newID,
            contract: contractWithNewFacilityID
        };
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data
        };

        const url = API.POST.facility.add;
        return msalFetch(url, axiosOptions)
            .then((response: AxiosResponse<any>) => {
                if (!response.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.FACILITY_UPDATE_SUCCESS,
                        facility: response.data
                    });
                    dispatch({ type: types.TOGGLE_MODAL_EDIT_FACILITY });
                    toastr.success(
                        'Success',
                        'Saved Facility',
                        constants.toastrSuccess
                    );
                    // wait for the select options to update then trigger an event that a new facility has been added.
                    setTimeout(() => {
                        const event = new CustomEvent('newFacility', {
                            detail: response.data.id
                        });
                        document.dispatchEvent(event);
                        document.dispatchEvent(
                            new CustomEvent('updatedCustomers')
                        );
                    }, 400);

                    return response;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.FACILITY_UPDATE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'add facility');
                console.error(error);
            });
    };
}

export function updateFacility(
    facility: IfacilityWithoutBuildings
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());

        const axiosOptions: AxiosRequestConfig = {
            method: 'put',
            data: facility
        };

        const url = API.PUT.updateFacility.replace('{id}', facility.id);
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.FACILITY_UPDATE_SUCCESS,
                    facility
                });
                dispatch({ type: types.TOGGLE_MODAL_EDIT_FACILITY });
                toastr.success(
                    'Success',
                    'Updated Facility',
                    constants.toastrSuccess
                );
                document.dispatchEvent(new CustomEvent('updatedCustomers'));
            })
            .catch((error: any) => {
                dispatch({
                    type: types.FACILITY_UPDATE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'update facility');
                console.error(error);
            });
    };
}

export function deleteFacility(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'delete'
        };
        const { selectedFacilityID } = getState().manageFacility;
        const url = API.DELETE.facility.replace('{id}', selectedFacilityID);
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.FACILITY_DELETE_SUCCESS,
                    payload: selectedFacilityID
                });
                dispatch({ type: types.TOGGLE_MODAL_EDIT_FACILITY });
                toastr.success(
                    'Success',
                    'Deleted Facility',
                    constants.toastrSuccess
                );
                document.dispatchEvent(new CustomEvent('updatedCustomers'));
            })
            .catch((error: any) => {
                dispatch({
                    type: types.FACILITY_DELETE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'delete facility');
                console.error(error);
            });
    };
}
// TODO rename to global set
export const setSelectedFacilityID = (id: string) => ({
    type: types.GLOBAL_SET_SELECTED_FACILITY_ID,
    id
});

export function getSAPJobMapping(): ThunkResult<any> {
    return (dispatch, getState) => {
        // If we already have the data, don't make the call again, this data should pretty much never change
        if (
            Object.keys(getState().sapJobMappings.sapJobMappingsByID).length > 0
        ) {
            return Promise.resolve();
        }

        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };

        const url = API.GET.sapJobMapping.getAll;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.SET_SAP_JOB_MAPPING,
                        data: data.data
                    });
                }
            })
            .catch((error: any) => {
                constants.handleError(error, 'get sap job mapping');
                console.error('[getSAPJobMapping]:', error);
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };
}

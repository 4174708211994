const root = process.env.REACT_APP_SERVER_DOMAIN;
const trainingRoot = process.env.REACT_APP_SERVER_DOMAIN_TRAINING;
console.info(
    `ENV: ${process.env.NODE_ENV} SERVER_DOMAIN: ${root}/ Version: ${process.env.REACT_APP_VERSION}`
);
// TODO now that we are using the same endpoint for POST, PUT and GET, refactor this so that they are no longer nested and repeated
const API = {
    POST: {
        building: `${root}/building/add`,
        facility: {
            add: `${root}/facility/add`,
            merge: `${root}/facility/MergeFacilities`
        },
        floor: `${root}/floor`,
        location: `${root}/location`,
        room: `${root}/room`,
        inventory: {
            updateproduct: `${root}/inventory/updateproduct`,
            addproduct: `${root}/inventory/addproduct`,
            quote: `${root}/inventory/beaconquote`,
            updateinstall: `${root}/inventory/update`,
            addinstall: `${root}/inventory/add`,
            deleteInstall: `${root}/inventory/delete`,
            installContact: `${root}/inventory/beaconcontact`,
            approveproduct: `${root}/inventory/approve`,
            importInstall: `${root}/inventory/excelimport`,
            mergeProduct: `${root}/inventory/mergeproduct`,
            moveFacility: `${root}/inventory/MoveFacilities`
        },
        user: {
            updatePostalCodes: `${root}/user/updatePostalCodes`,
            login: `${root}/user/login`,
            signup: `${root}/user/signup`,
            adSignup: `${root}/user/adsignup`,
            approve: `${root}/user/approve`,
            reject: `${root}/user/reject`,
            update: `${root}/user/update`,
            updateprofile: `${root}/user/updateprofile`,
            updateteam: `${root}/user/updateteammember`,
            saveteam: `${root}/user/saveteammember`,
            deleteTeamMember: `${root}/user/deleteteammember`,
            saveTrainingPO: `${root}/user/savetrainingpo`
        },
        training: {
            savelessonprogress: `${root}/training/savelessonprogress`,
            trainingCheckout: `${root}/training/savetrainingtransaction`,
            savequiz: `${root}/training/savequiz`,
            startQuiz: `${root}/training/startquiz`
        },
        measurementPoint: {
            addglobalmpl: `${root}/MeasurementPoint/AddGlobalMeasurementPointList`,
            addResults: `${root}/MeasurementPoint/AddJobMeasurementPointListResults`
        },
        report: {
            run: `${root}/report/run`,
            runDailyRounds: `${root}/report/RunDailyRounds`
        },
        brand: `${root}/brand`,
        alert: {
            create: `${root}/alert/add`,
            markAsRead: `${root}/alert/{alertId}/viewed`,
            markAllAsRead: `${root}/alert/all/viewed`
        },
        photo: {
            add: `${root}/photo/add`
        },
        job: {
            create: `${root}/job/CreateJob`
        },
        workOrder: {
            add: `${root}/workOrder/add`
        }
    },
    PUT: {
        building: `${root}/building/update`,
        floor: `${root}/floor`,
        location: `${root}/location`,
        room: `${root}/room`,
        measurementPoint: {
            updateglobalmpl: `${root}/MeasurementPoint/UpdateGlobalMeasurementPointList`,
            updatecustomermpl: `${root}/MeasurementPoint/UpdateCustomerMeasurementPointList`
        },
        brand: `${root}/brand`,
        brandLogo: `${root}/brand/UploadLogo`,
        alert: `${root}/alert/update`,
        facility: `${root}/facility/{id}`,
        updateFacility: `${root}/facility/update/{id}`,
        inventory: {
            updateInstalls: `${root}/inventory/updateInstalls`
        }
    },
    DELETE: {
        building: `${root}/building/deletebuilding`,
        floor: `${root}/floor/deletefloor`,
        location: `${root}/location/deletelocation`,
        room: `${root}/room/deleteroom`,
        measurementPoint: {
            deleteglobalmpl: `${root}/MeasurementPoint/deleteglobalmeasurementpointlist`,
            deletecustomermpl: `${root}/MeasurementPoint/deletecustomermeasurementpointlist`,
            deleteglobalmeasurementpoint: `${root}/MeasurementPoint/deleteglobalmeasurementpoint`,
            deletecustomermeasurementpoint: `${root}/MeasurementPoint/deleteglobalmeasurementpoint`,
            deleteglobalmeasurementpointselectoption: `${root}/MeasurementPoint/deleteglobalmeasurementpointselectoption`,
            deletecustomermeasurementpointselectoption: `${root}/MeasurementPoint/deletecustomermeasurementpointselectoption`
        },
        user: `${root}/user/deleteaccount`,
        brand: `${root}/brand`,
        alert: `${root}/alert`,
        facility: `${root}/facility/DeleteFacility/{id}`
    },
    GET: {
        sapJobMapping: {
            getAll: `${root}/SAPJobMapping/GetAll`
        },
        building: {
            getall: `${root}/building/getall`
        },
        customer: {
            getbyid: `${root}/customer/{customerId}`,
            getall: `${root}/customer/getall`,
            getByCountry: `${root}/customer/GetByCountry`,
            getallwithfacilities: `${root}/customer/getallwithfacilities`,
            search: `${root}/customer/search`,
            getlogo: `${root}/customer/{customerId}/icon`
        },
        facility: {
            search: `${root}/facility/search`,
            GetFacilityQueue: `${root}/facility/GetFacilityQueue`,
            searchByCountries: `${root}/facility/SearchByCountries`,
            getbyid: `${root}/facility`,
            getbycustomer: `${root}/facility/getbycustomer`
        },
        inventory: {
            getinventory: `${root}/inventory/search`,
            getByFacility: `${root}/inventory/getbyfacility`,
            getproductinfo: `${root}/inventory/getproductinfo`,
            getproductqueue: `${root}/inventory/getproductqueue`,
            products: `${root}/inventory/products`
        },
        floor: {
            getall: `${root}/floor/getall`
        },
        location: {
            getall: `${root}/location/getall`
        },
        room: {
            getall: `${root}/room/getall`
        },
        user: {
            getuserqueue: `${root}/user/getuserqueue`,
            getfseusers: `${root}/user/getfseusers`,
            getusersbysecurityfunction: `${root}/user/getusersbysecurityfunction`,
            getusersearch: `${root}/user/search`,
            getteamsearch: `${root}/user/searchteam`
        },
        training: {
            getprogressbylesson: `${root}/training/getprogressbylesson`,
            getalllessonprogress: `${root}/training/getalllessonprogress`,
            getPurchasedTraining: `${root}/training/getpurchasedtraining`,
            getAdminProgress: `${root}/training/getadminprogress`,
            getQuizResults: `${root}/training/getquizresults`,
            classMarker: (userId: string) =>
                `${root}/ClassMarker/GetResultsByUser/${userId}`,
            getQuizLink: (courseId: string) =>
                `${root}/training/getquizlink/${courseId}`,
            savePendingTraining: `${root}/training/SavePendingTransactions`
        },
        trainingCurriculum: {
            allCourses: `${root}/training/getcourses`,
            allLessons: `${root}/training/getlessons`,
            lessonByCourseID: `${root}/training/getlessonsbycourse`,
            quizzesByLessonID: `${root}/training/getquizzesbylesson`,
            allQuizzes: `${root}/training/getquizzes`
        },
        smartlinkDataPoint: {
            getAll: `${root}/smartlinkdatapoint/getall`
        },
        measurementPoint: {
            getall: `${root}/measurementpoint/GetAllMeasurementPointLists`,
            getJobResults: `${root}/MeasurementPoint/GetJobMeasurementPointListResults`,
            getFacilityMeasurementPointListResults: `${root}/MeasurementPoint/GetFacilityMeasurementPointListResults`,
            getMeasurementPointList: `${root}/measurementpoint/getmeasurementpointlist`,
            getMeasurementPointListResultWithAnswers: `${root}/measurementpoint/getmeasurementpointlistresultwithanswers`,
            getMeasurePointListResultForInstallBase: `${root}/measurementpoint/GetInstallBaseMeasurementPointListResults`
        },
        report: {
            defaults: `${root}/report/defaults`
        },
        brand: {
            search: `${root}/brand/search`,
            getAll: `${root}/brand/getallbrands`,
            getsingle: `${root}/brand`
        },
        alert: {
            single: `${root}/alert/{alertId}`,
            all: `${root}/alert/getall`,
            search: `${root}/alert/search`,
            user: `${root}/alert/user`
        }
    },
    alert: {
        alertsForInstallBase: `${root}/alert/AlertsForInstallBase`
    },
    cloudDocument: {
        getTypeByCustomer: `${root}/cloudDocumentType/getByCustomer`,
        documentType: `${root}/cloudDocumentType`,
        search: `${root}/cloudDocument/search`,
        single: `${root}/cloudDocument`, // {id}
        download: `${root}/cloudDocument/{documentID}/download`, // {id}/download
        downloadVersion: `${root}/cloudDocument/{documentID}/version/{versionID}/download`,
        getAll: `${root}/cloudDocumentType/GetAll`,
        add: `${root}/cloudDocument/add`,
        delete: `${root}/cloudDocument/deleteCloudDocument`,
        installSearch: `${root}/cloudDocument/installSearch`,
        preview: `${root}/cloudDocument/preview`
    },
    update: {
        dashboard: `${root}/update/dashboard`
    },
    jobComment: {
        search: `${root}/jobcomment/search`,
        single: `${root}/jobcomment`,
        resolve: `${root}/jobcomment/resolve`
    },
    code: {
        search: `${root}/code/search`,
        single: `${root}/code`
    },
    smartlink: {
        alert: `${root}/smartlink/alert`
    },
    contact: {
        getall: `${root}/contact/getall`,
        add: `${root}/contact/add`,
        update: `${root}/contact`,
        findUserOrContactByEmail: `${root}/contact/finduserorcontactbyemail`,
        getByFacility: `${root}/contact/getbyfacility`,
        updateFacilities: `${root}/contact/{id}/updateFacilities`,
        addFacility: `${root}/contact/{id}/addFacility`,
        deleteFacility: `${root}/contact/{id}/deletefacility`
    },
    preventativeMaintenanceChecklist: {
        getAll: `${root}/preventativeMaintenanceChecklist/getall`,
        single: `${root}/preventativeMaintenanceChecklist`
    },
    preventativeMaintenancePoint: {
        getCSV: `${root}/PreventativeMaintenancePoint/getCSV`
    },
    tae: {
        search: `${root}/tae/search`
    },
    job: {
        single: `${root}/job`,
        search: `${root}/job/search`,
        getAll: `${root}/job/getall`,
        getAllOpenJobsForWorkOrders: `${root}/job/GetAllOpenJobsForWorkOrders`,
        getassigned: `${root}/job/getassigned`,
        getByFacility: `${root}/job/getbyfacility`,
        getNextAutoNumber: `${root}/job/getnextautonumber`,
        addWorkOrders: `${root}/job/addworkorders`,
        delete: `${root}/job/deletejob`
    },
    lead: {
        bulkUpdateStatus: `${root}/Lead/BulkUpdateStatus`,
        getAll: `${root}/Lead/GetAll`,
        search: `${root}/Lead/Search`,
        single: `${root}/lead`
    },
    leadActivity: {
        getAll: `${root}/LeadActivity/GetLeadActivityForLead`,
        single: `${root}/LeadActivity`
    },
    leadInstallBase: {
        single: `${root}/LeadInstallBase`
    },
    quote: {
        search: `${root}/quote/search`,
        updateStatus: `${root}/quote/updatestatus`,
        saveQuoteFiles: `${root}/quote/saveQuoteFiles`
    },
    photo: {
        search: `${root}/photo/search`,
        getByProduct: `${root}/photo/getByProduct`,
        deletephoto: `${root}/photo/deletephoto?photoID={id}`,
        deleteMultiplePhotos: `${root}/photo/deletephotos`
    },
    workOrder: {
        single: `${root}/workorder`,
        search: `${root}/workorder/search`,
        getTechnicians: `${root}/workorder/GetTechnicians`,
        getWorkOrderCsv: `${root}/workorder/GetWorkOrderCSV`,
        getFSEsWithWorkOrders: `${root}/workorder/getfseswithworkorders`,
        unlinkWorkOrders: `${root}/workorder/UnlinkWorkOrders`
    },
    part: {
        search: `${root}/part/search`,
        get: `${root}/part/GetJobParts`,
        save: `${root}/part/SaveJobParts`
    },
    suggestedParts: {
        search: `${root}/part/SearchSuggestedParts`,
        save: `${root}/part/SaveSuggestedParts`
    },
    country: {
        getAll: `${root}/country/getAll`
    }
};

export default API;
